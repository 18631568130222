<template lang="pug">
v-container
	ObjectManagementList(
		:items="items"
		:formats="formats ?? {}"
		:appearances="appearances"
		:dataTableOptions.sync="dataTableOptions"
		:loading.sync="loading"
		:initialDataCreateForm="initialDataCreateForm"
		:textAdd="{ header: $t('objects.event.create.title') }"
		:textUpdate="{ header: $t('objects.event.update.title') }"
		:textDelete="{ header: $t('objects.event.delete.title'), html: `<p>${$t('objects.event.delete.willBeDeleted')}</p><p><b>${$t('objects.event.delete.questionShallBeDeleted')}</b></p>` }"
		:rules="$store.state.validation.rules"
		:ruleMessages="$store.state.validation.ruleMessages"
		@search="onSearch"
		@add-validation:success="request(requests.create, {body: $event}).then(() => { updateItems(true) })"
		@update-validation:success="request(requests.update, {url: requests.delete.axios.url + '/' + $event._id, body: $event}).then(() => { updateItems(true) })"
		@delete:confirmation="request(requests.delete, {url: requests.delete.axios.url + '/' + $event._id}).then(() => { updateItems(true) })"
		:showUpdate="Array.isArray(formats?.update) && formats.update.length > 0"
		:showDelete="Array.isArray(formats?.create) && formats.create.length > 0"
	)
		template(#header)
			div
				h1.myTitle {{ $tc('objects.event.title', 2) }}
				p.mb-0 {{ $tc('objects.event.overview') }}.
		//- template(#general-actions="{format, appearance, rules, value, input}")
			v-btn.mr-2(
				fab
				small
				depressed
				color="secondary"
				@click="dialogs.import = true"
			)
				v-icon mdi-upload
		template(#item.active="item")
			v-chip.px-6(v-if="item.active" color="success" small).font-weight-bold {{ $t('common.active') }}
			v-chip.px-6(v-else="item.active" color="error" small).font-weight-bold {{ $t('common.inactive') }}
		template(#item.start="item")
			div {{ new Date(item.start).toLocaleString($i18n.locale) }}
		template(#item.end="item")
			div {{ new Date(item.end).toLocaleString($i18n.locale) }}
		template(#item.actions.extra="{ item }")
			v-btn.mr-2(
				v-show="$store.getters['authorization/userCan']('read', urlBase, 'any') || ($store.getters['authorization/userCan']('read', urlBase, 'own') && $store.state.user?._events?.includes(item._id) || $store.state.user?._events?.length === 0)"
				color="grey"
				fab
				x-small
				depressed
				@click="$router.push({ path: '/event', query: { eventId: item._id } })"
			)
				v-icon.white--text mdi-cursor-default-click

	ActionLogs(
		:header="$t('Portal.settings.lastActivities')"
		itemsPerPage="5"
		ref="actionLogs"
	)
</template>

<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';
import ActionLogs from '@/views/ActionLogs.vue';

export default
{
	name: 'Admin',
	components:
	{
		ObjectManagementList: require('@/components/ObjectManagementList.vue').default,
		ActionLogs
	},
	created ()
	{
		//RequestHandler.requests(this.requests.initialize, this);
		RequestHandler.requests(
			[
				this.requests.get,
				this.requests.format.update,
				this.requests.format.create
			],
			this
		).catch(() => {});
	},
	methods:
	{
		log: console.log,

		request: RequestHandler.request,

		updateItems (forceLoadingLogs = false)
		{
			// prevent the watcher from triggering itself
			if (this.loading)
			{
				return;
			}

			this.loading = true;

			RequestHandler.request(
				this.requests.get,
				null,
				this,
				Object.assign(this.dataTableOptions, { search: this.search })
			).then((response) =>
			{
				this.maximumItemCount = parseInt(response.headers.get('pagination-documents'));
				if (forceLoadingLogs)
				{
					this.$refs.actionLogs.loadActions();
				}

				setTimeout(() => {this.loading = false}, 0);
			});
		},
		
		mergeFormat: function ()
		{
			const additionalValues =
			{
				title:
				{
					minimumLength: 2,
					maximumLength: 128
				},
				description:
				{
					minimumLength: 0,
					maximumLength: 1024
				},
				supervisor:
				{
					minimumLength: 2,
					maximumLength: 128
				}
			};
			
			for (const field of this.formats.create)
			{
				if (additionalValues[field.name])
				{
					for (const [key, value] of Object.entries(additionalValues[field.name]))
					{
						field[key] = value;
					}
				}
			}
		},
		
		onSearch (search)
		{
			if (this.loading)
			{
				return;
			}

			if (search !== this.search)
			{
				this.dataTableOptions.page = 1;
			}
			this.search = search;

			return this.updateItems();
		}
	},
	
	// temporary: values should come from server in the future instead
	watch:
	{
		'formats.create': function () {this.mergeFormat();},
		'formats.update': function () {this.mergeFormat();}
	},
	
	computed:
	{
		requests ()
		{
			return {
				create:
				{
					axios:
					{
						url: this.urlBase,
						method: 'post'
					}
				},
				update:
				{
					axios:
					{
						url: this.urlBase,
						method: 'put'
					}
				},
				delete:
				{
					axios:
					{
						url: this.urlBase,
						method: 'delete'
					}
				},
				get:
				{
					store: 'items',
					axios:
					{
						url: this.urlBase
					}
				},
				format:
				{
					create:
					{
						store: 'formats.create',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'create'
							}
						}
					},
					update:
					{
						store: 'formats.update',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'update'
							}
						}
					}
				}
			}
		}
	},
	data: (component) =>
	({
		urlBase: 'events',
		items: [],
		formats: {create: [], update: []},
		appearances:
		[
			{
				name: 'title',
				label: component.$t('pages.Event.title')
			},
			{
				name: 'description',
				label: component.$t('pages.Event.description')
			},
			{
				name: 'supervisor',
				label: component.$t('pages.Event.supervisor')
			},
			{
				name: 'active',
				label: component.$t('pages.Event.active')
			},
			{
				name: 'start',
				label: component.$t('pages.Event.start')
			},
			{
				name: 'end',
				label: component.$t('pages.Event.end')
			}
		],
		initialDataCreateForm:
		{
			active: true
		},
		loading: false,
		search: '',
		dataTableOptions: {},//{itemsPerPage: 5},
		maximumItemCount: 0,
	})
}
</script>