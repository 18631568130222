export default (function ()
{
	const translations =
	{
		user:
		{
			role:
			{
				admin: 'Administrator',
				operator: 'Operator',
				speaker: 'Redner'
			},
		},
		file:
		{
			type:
			{
				ppt: 'Präsentation',
				pdf: 'PDF',
				video: 'Video',
				font: 'Schriftart'
			},
			status:
			{
				missing: 'Datei fehlt',
				uploaded: 'Datei hochgeladen',
				review: 'Datei in Prüfung',
				rejected: 'Datei abgelehnt',
				approved: 'Datei genehmigt'
			}
		},
	};

	function mapFormats (formats, objectType)
	{
		const map = (array) =>
		{
			return array.map(f =>
			{
				if (translations[objectType] &&
					translations[objectType][f.name] &&
					f.values)
				{
					f.values = Object.assign({}, translations[objectType][f.name]);
				}
				return f;
			});
		};

		if (Array.isArray(formats))
		{
			formats = map(formats);

			return formats;
		}

		for (const key of Object.keys(formats))
		{
			if (Array.isArray(formats[key]))
			{
				formats[key] = map(formats[key]);
			}
		}
		return formats;
	}

	return {
		translations: Object.freeze(translations),
		mapFormats,
	};
}());