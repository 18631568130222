var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.user?.auth_token && _vm.$store.state.user.role !== 'speaker'),expression:"$store.state.user?.auth_token && $store.state.user.role !== 'speaker'"}],staticClass:"transparent",attrs:{"app":"","fixed":"","clipped":"","touchless":"","temporary":_vm.$vuetify.breakpoint.mdAndDown,"permanent":_vm.$vuetify.breakpoint.lgAndUp,"width":_vm.$vuetify.breakpoint.smAndDown ? '66vw' : 300},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"white--text",staticStyle:{"padding":"5px 15px","font-size":"x-small"}},[_vm._v("Version "+_vm._s(_vm.$store.state.appVersion)+", powered by G+B Interactive"),_c('sup',[_vm._v("®")])])]},proxy:true}]),model:{value:(_vm.$store.state.isSidebar),callback:function ($$v) {_vm.$set(_vm.$store.state, "isSidebar", $$v)},expression:"$store.state.isSidebar"}},[_c('v-list',{attrs:{"nav":""}},[_c('div',{staticClass:"d-flex"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],staticClass:"mb-4",attrs:{"to":"/"}},[_c('v-img',{attrs:{"contain":"","width":"140px","src":require('@/assets/logo_colored.png'),"title":`Logo ${_vm.$store.state.company.firm.long}`,"alt":`Logo ${_vm.$store.state.company.firm.long}`}})],1)],1),_vm._l((_vm.navigation),function(item,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
				(!item.requireUrlParameter && !item.excludeUrlParameter) ||
				(item.requireUrlParameter && _vm.$route.query[item.requireUrlParameter]) ||
				(item.excludeUrlParameter && !_vm.$route.query[item.excludeUrlParameter])
			),expression:"\n\t\t\t\t(!item.requireUrlParameter && !item.excludeUrlParameter) ||\n\t\t\t\t(item.requireUrlParameter && $route.query[item.requireUrlParameter]) ||\n\t\t\t\t(item.excludeUrlParameter && !$route.query[item.excludeUrlParameter])\n\t\t\t"}],key:i,staticClass:"mb-6"},[_c('div',{staticClass:"navigation-link d-flex align-center",class:[
				item?.indent ? 'ml-9' : '',
			]},[_c('div',{staticClass:"d-flex align-center no-link",on:{"click":function($event){return _vm.navigate(item)}}},[_c('v-icon',{class:_vm.$route.path.includes(item?.link) ? 'font-weight-bold tertiary--text' : '',attrs:{"color":"white"}},[_vm._v(_vm._s(item.icon))]),_c('v-subheader',{staticClass:"text-h6 white--text",class:_vm.$route.path.includes(item?.link) ? 'font-weight-bold tertiary--text' : ''},[_vm._v(_vm._s(_vm.$tc(item.label, item.indexInI18n)))])],1),_c('v-divider',{staticClass:"mx-auto"})],1)])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }