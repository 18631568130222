<template>
	<!-- component :is does not work with vuetify components unfortunately (for using v-avatar / v-list-item-avatar, so we add classes + fixed size manually) -->
	<!-- unset color when using image because background might be partly visible -->
	<v-avatar
		:class="`${listItem ? 'my-2 mr-4' : ''}`"
		:size="listItem ? 40 : size"
		:color="url ? null : color"
	>
		<v-img
			v-show="url"
			:src="url"
		/>
		<span
			v-show="!url && name"
			class="light--text"
		>
			{{ initials }}
		</span>
		<v-icon
			v-show="!url && !name"
			class="light--text"
		>
			mdi-account-circle
		</v-icon>
	</v-avatar>
</template>

<script>
export default
{
	name: 'DynamicAvatar',
	
	props:
	{
		listItem:
		{
			type: Boolean,
			default: false
		},
		size:
		{
			default: 32
		},
		color:
		{
			type: String,
			default: ''
		},
		url:
		{
			type: String,
			default: ''
		},
		name:
		{
			type: String,
			default: '',
			
		}
	},
	
	computed:
	{
		initials ()
		{
			if (!this.name)
			{
				return '';
			}
			
			const name = this.name.trim();
			
			const splitPosition = name.indexOf(' ');
			
			if (!~splitPosition)
			{
				return name.charAt(0).toUpperCase();
			}
			
			return name.slice(0, 1).toUpperCase() + name.slice(splitPosition + 1, splitPosition + 2).toUpperCase();
		},
	}
}
</script>

<style lang="scss" scoped>
*
{
	user-select: none;
}
</style>