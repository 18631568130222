<template>
	<v-container
		class="d-flex justify-center"
	>
		<v-card
			max-width="600"
			class="mt-12 pa-8 d-flex flex-column justify-center"
		>
			<div class="errorCode">404</div>
			<h2>
				{{ $t('pages.404.title') }}
			</h2>
			<v-divider class="my-6"/>
			<p
				class="mb-0"
			>
				{{ $t('pages.404.description') }}<br>
				<i18n
					path="pages.404.suggestedLinks"
					tag="span"
					class="mb-0"
				>
					<template #back>
						<a href="#" @click.prevent="$router.go(-1)">{{ $t('pages.404.links.back') }}</a>
					</template>
					<template #start>
						<router-link to="/">{{ $t('pages.404.links.start') }}</router-link>
					</template>
				</i18n>
			</p>
		</v-card>
	</v-container>
</template>

<script>
export default
{
	created ()
	{
		
	},
	
	methods:
	{
		
	},
	
	data: () =>
	(
		{
			
		}
	)
};
</script>

<style lang="scss" scoped>
.errorCode
{
	font-size: 4.5rem;
	color: var(--v-error-base);
}
</style>