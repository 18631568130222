<template lang="pug">
div.d-flex.secondary.white--text.pa-4(
	:style="`width: ${width}`"
)
	v-icon.white--text.mr-4(x-large) {{ icon }}
	div
		div {{ $t(title) }}
		h2 {{ value }}
</template>

<script>
export default
{
	name: 'Highlight',
	props:
	{
		width:
		{
			type: String,
			default: 'auto'
		},

		icon:
		{
			type: String,
			default: ''
		},

		title:
		{
			type: String,
			default: ''
		},

		value:
		{
			type: String,
			default: ''
		}
	}
}
</script>