<template>
	<v-btn
		:loading="loading"
		:disabled="loading"
		v-bind="attributes"
		@click="clickWrapper"
	>
		<slot/>
	</v-btn>
</template>

<script>
export default
{
	name: 'RequestButton',
	
	props:
	{
		click:
		{
			type: Function,
			default: new Promise((resolve) => {resolve()})
		},
		
		attributes:
		{
			type: Object,
			default: () => ({})
		}
	},
	
	methods:
	{
		clickWrapper: function ()
		{
			this.loading = true;
			
			return this.click().finally(() => 
			{
				this.loading = false;
			});
		}
	},
	
	data: (component) =>
	({
		loading: false
	})
}
</script>

<style lang="scss" scoped>

</style>