<template lang="pug">
v-container
	.customCard(style="max-width: 1200px")
		h1 {{ $t('pages.Home.Speaker.title') }},&nbsp;
			span.secondary--text {{ $store.getters['user/getName'] }}
			| !

		.d-flex.error.white--text.mt-8.mb-13.py-5(v-if="sessionsForSpeaker.length && initialized && (filteredFilesThatAreMissing.length || !filesForSpeakersSessions.length)")
			v-icon.ml-7.mr-10(color="white", x-large) mdi-exclamation-thick
			div
				.title.mb-4
					h1(v-if="filteredFilesThatAreMissing.length <= 1") {{ $tc('pages.Home.Speaker.missingFilesNotification.title', 1) }}
					h1(v-else) {{ $tc('pages.Home.Speaker.missingFilesNotification.title', 1) }}
				.subtitle
					p(v-if="filteredFilesThatAreMissing.length <= 1") {{ $tc('pages.Home.Speaker.missingFilesNotification.subtitle', 1) }}
					p(v-else) {{ $tc('pages.Home.Speaker.missingFilesNotification.subtitle', 2) }}

		.sessionsWhereSpeakerUsesPresentations(v-if="initialized && sessionsForSpeaker.length")
			p {{ $t('pages.Home.Speaker.main.title') }}

			.session(v-for="(session, j) in sessionsForSpeaker")
				router-link.no-link.sessionEntry.d-flex.align-center.font-weight-bold(:to="`/sessionfiles?sessionId=${session?._id}&eventId=${session?.event}`")
					v-icon.mr-5(v-if="shouldAlert(session)" large color="error") mdi-alert
					v-icon.mr-5(v-else-if="shouldIndicateProcessing(session)" large color="review") mdi-progress-clock
					v-icon.mr-5(v-else large color="success") mdi-check
					h4(v-if="session?.start") {{ (new Date(session.start))?.toLocaleString($i18n.locale, {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute: '2-digit'}) }}
					h4 {{ session.topic }} - {{ session?.subtopic }}
					v-spacer
					.d-flex.align-center
						h4(v-if="room = findRoom(session)") {{ room?.title }} | {{ room?.description }}
						h4(v-else) {{ $t('pages.Home.Speaker.main.noRoomAssigned') }}
						v-icon(x-large) mdi-chevron-right
				v-divider
		.sessionsWhereSpeakerUsesPresentations(v-else-if="initialized")
			div.error--text
				h2 {{ $t('pages.Home.Speaker.main.notToSessionAssigned') }}
				b {{ $t('pages.Home.Speaker.main.getInContact') }}
</template>


<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';
import DateHandler from '@/customModules/utils/DateHandler.js';

export default
{
	name: 'Speaker',
	components:
	{
		GenericForm: require('/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue').default,
		RequestButton: require('@/components/RequestButton.vue').default,
	},
	created ()
	{
		RequestHandler.requests(this.requests.initialize, this)
		.then(() =>
		{
			this.initialized = true;
		});
	},
	computed:
	{
		filteredFilesThatAreMissing(){
			let missing = [];

			this.sessionsForSpeaker.forEach(sess => {
				const filesWithinSession = sess.files;

				missing = missing.concat(filesWithinSession.filter(file => file.status === 'missing'));
			});

			return missing;
		},
		sessionsForSpeaker ()
		{
			return this.sessions.filter(s => s.speakers?.find(u => `${u._id}` === this.$store.state.user._id));
		},
		filesForSpeakersSessions ()
		{
			let files = [];

			this.sessionsForSpeaker.forEach(sess => {
				files = files.concat(sess.files ?? []);
			});

			return files;
		}
	},
	data: (component) =>
	({
		initialized: false,
		
		rooms: [],
		sessions: [],
		
		requests:
		{
			initialize:
			[
				{
					store: 'rooms',
					axios:
					{
						url: `rooms`,
					},
				},
				{
					store: 'sessions',
					axios:
					{
						url: `sessions`,
					},
				},
			],
		}
	}),
	methods:
	{
		log: console.log,
		convertUTCDateToLocalDate: DateHandler.convertUTCDateToLocalDate,
		findRoom (session)
		{
			return this.rooms.find(r => r.sessions?.find(s => s._id === session._id));
		},
		shouldAlert (session)
		{
			// no files at all
			if (!session.files?.length)
			{
				return true;
			}
			// file is missing or rejected
			if (session.files.filter(sess => sess.status === 'missing' || sess.status === 'rejected').length)
			{
				return true;
			}

			return false;
		},
		shouldIndicateProcessing (session)
		{
			// no files at all
			if (!session.files?.length)
			{
				return false;
			}
			// file is in review
			if (session.files.filter(sess => sess.status === 'review').length)
			{
				return true;
			}

			return false;
		}
	},
}
</script>

<style lang="sass" scoped>
	.sessionEntry
		h4
			flex: auto
			flex-wrap: nowrap
</style>