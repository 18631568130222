import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Store from '@/store';
import configuration from '@/configuration.json';
import Utility from '/node_modules/@gb-net/utility/utility.js';

Vue.use(VueI18n);

function loadLocaleMessages ()
{
	const context = require.context('./../locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	
	context.keys().forEach(fileName =>
	{
		const pathElements = Utility.string.removeSuffix(
			Utility.string.removePrefix(fileName, './'),
			'.json'
		).split('/');
		
		const name = pathElements.pop();
		
		Utility.object.integrateValue(
			messages,
			[
				...pathElements,
				name.split('.')?.[1] ?? name
			],
			context(fileName)
		);
	});
	
	return messages;
}

const userLocale = localStorage.getItem('locale');

export default new VueI18n(
{
	locale: userLocale,
	fallbackLocale: configuration.locale,
	messages: loadLocaleMessages()
});