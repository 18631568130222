<template lang="pug">
v-app
	v-img(:src="require('@/assets/background.png')" style="position: fixed; min-height: 100vh; min-width: 100vw; scale: 1.1; filter: blur(0px) brightness(0.95)")
	template(v-if="!$store.state.noNavigation.includes($route.name)")
		Header(
			logo="logo_white.png"
			color="white"
			:hideUserMenuListItems="['profile']"
			@update:drawerOpen="$store.sidebarOpen = $event"
			@logout="$store.commit('user/logout')"
		)
		Sidebar
	v-main
		router-view
	//- Footer(v-once
		:footerLinks="$t('footer.about')"
		:companyWebsite="Company.website"
		:companyName="Company.firm.long")
	Snackbar
</template>

<script>
export default
{
	name: 'App',
	components:
	{
		Header: require("@/components/basics/Header.vue").default,
		Sidebar: require("@/components/basics/Sidebar.vue").default,
		Footer: require("@/components/basics/Footer.vue").default,
		Snackbar: require("@/components/basics/Snackbar.vue").default,
	},
};
</script>

<style lang="sass">
$fontWeights: ('L': 300, 'R': 400, 'M': 500, 'B': 700)
@each $key, $fontWeight in $fontWeights
	@font-face
		font-family: 'Ubuntu'
		src: url('fonts/Ubuntu/Ubuntu-' + $key + '.ttf')
		font-weight: $fontWeight
	@font-face
		font-family: 'Ubuntu'
		src: url('fonts/Ubuntu/Ubuntu-' + $key + 'I.ttf')
		font-weight: $fontWeight
		font-style: italic
	
	$primary: 'red'
	$secondary: 'blue'
	
	.no-link
		text-decoration: none
	
	.container
		padding: 20px
		margin: 0
	
	.form
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		max-width: 550px
		width: 90vw

	.absoluteRight
		position: absolute
		top: 0
		right: 0
		z-index: 1
	
	.text-overflow
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		width: 100px
	
	.backToPreviousPage
		cursor: pointer

	.myTitle
		color: var(--v-primary-base)

	.customCard
		background-color: rgba(white, 85%)
		border-radius: 20px !important
	
	.customCard:not(.v-card)
		padding: 16px
	
	.v-dialog .customCard
		background-color: rgba(white, 100%)
</style>