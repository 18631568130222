export default (function ()
{
	function restrict(array, name, value)
	{
		return array.map(e => 
		{
			if (e?.name !== name ||
				typeof e?.values !== 'object'
			)
			{
				return e;
			}

			if (Array.isArray(e.values))
			{
				e.values = e.values.filter(i => i === value);

				return e;
			}

			if (e.values[value])
			{
				e.values = { [value]: e.values[value] };
			}

			return e;
		})
	}

	function restrictFormatsToEvent (formats, eventId)
	{
		if (!Array.isArray(formats) || typeof eventId !== 'string')
		{
			return formats;
		}
		
		let result = restrict(formats, 'event', eventId);

		// we have to restrict twice because of different properties for users and other entities.
		return restrict(result, '_events', eventId);
	}

	function restrictFormatsToSession (formats, sessionsId)
	{
		if (!Array.isArray(formats) || typeof sessionsId !== 'string')
		{
			return formats;
		}
		
		return restrict(formats, 'session', sessionsId);
	}

	return {
		restrictFormatsToEvent,
		restrictFormatsToSession
	};
}());