<template lang="pug">
v-dialog(
	v-model="show",
	width="calc(400px + 40vw)",
	max-width="700px",
	persistent
)
	v-card
		.py-3.px-4
			h2 {{ header }}
			p {{ subheader }}

			.myInformation.grey.lighten-3.rounded.px-3.pt-3.pb-1
				h4.mb-4 {{ $t('common.hint') }}

				p.mb-1 {{ $t('components.ImportEventForm.descriptionFollowingTableHeaders') }}
				ul.mb-2
					li {{ $t('components.ImportEventForm.descriptionMustBeCommaSeparated') }}:&nbsp;
						b speakers, sessions
					li {{ $t('components.ImportEventForm.descriptionDateFormat') }}:&nbsp;
						b start, end

				p {{ $t('components.ImportEventForm.descriptionExamplesCorrectImplementation') }}

			p(
				style="font-size: small;"
			) {{ $t('components.ImportEventForm.tableFormatSingleSheet') }}:

			div(
				v-for="(excelSheet, i) in examples"
				:key="i"
			).mt-5
				h3(v-if="excelSheet?.title") {{ excelSheet.title }}
				v-simple-table(
					dense
				)
					template
						thead
							tr
								th(
									class="text-left"
									style="font-size: x-small;"
									v-for="tableItem in excelSheet.items"
									:key="tableItem.key"
								) {{ tableItem.key }}{{ tableItem.required ? '*' : '' }}
						tbody
							tr
								th(
									class="text-left"
									style="font-size: x-small; font-weight: normal;"
									v-for="tableItem in excelSheet.items"
									:key="tableItem.key"
								) {{ tableItem.value }}

				p(
					v-if="excelSheet.items.length && excelSheet.items.find(e => e.key === 'role')"
					style="font-size: small; color: #666;"
				) {{ $t('components.ImportEventForm.validValuesForRole') }} "role":&nbsp;
					b {{ (excelSheet.items.find(e => e.key === 'role')).valid }}

			GenericForm.mt-10(
				ref="importForm",
				:format="uploadFormat",
				:appearances="appearances",
				:initialData="initialImportData",
				@validation:success="importUsers($event)"
			)

			template
				v-card-actions
					v-spacer
					v-btn.grey.white--text(
						@click="$emit('import-users:cancel')"
					) {{ $t('common.cancel') }}
					v-btn.green.white--text(
						@click="$refs.importForm.validate()"
					) {{ $t('common.import') }}
</template>

<script>
export default {
	name: 'ImportEventForm',

	props:
	[
		'header',
		'subheader',
		'show'
	],

	components:
	{
		GenericForm: require('/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue').default,
	},

	data: (component) => ({
		urlBase: 'events',

		uploadFormat: [{
			name: 'file',
			required: false,
			type: 'file',
			extensions: ['xlsx',],
		}],

		appearances: [
			{
				name: 'file',
				label: 'Excelliste hochladen',
			},
		],

		initialImportData: {},

		examples:
		[
			{
				title: 'Users',
				items:
				[
					{
						key: 'ID',
						value: '1',
						required: true,
					},
					{
						key: 'lastname',
						value: 'Mustermann',
						required: true,
					},
					{
						key: 'firstname',
						value: 'Max',
						required: true,
					},
					{
						key: 'email',
						value: 'mm@company.org',
						required: true,
					},
					{
						key: 'password',
						value: '1234',
						required: true,
					},
					{
						key: 'company',
						value: 'Company Inc.',
						required: false,
					},
					{
						key: 'phone',
						value: '0123/4567-8',
						required: false,
					},
					{
						key: 'role',
						value: 'speaker',
						valid: 'admin, operator, speaker',
						required: false,
					},
				],
			},
			{
				title: 'Sessions',
				items:
				[
					{
						key: 'ID',
						value: '1',
						required: true,
					},
					{
						key: 'topic',
						value: 'Event App',
						required: true,
					},
					{
						key: 'subtopic',
						value: 'Veranstaltungen zu einem Erlebnis machen',
						required: false,
					},
					{
						key: 'start',
						value: '12.08.2024  10:00:00',
						required: true,
					},
					{
						key: 'end',
						value: '12.08.2024  10:55:00',
						required: true,
					},
					{
						key: 'speakers',
						value: '2, 3',
						required: false,
					},
				],
			},
			{
				title: 'Rooms',
				items:
				[
					{
						key: 'ID',
						value: '1',
						required: true,
					},
					{
						key: 'title',
						value: 'D-05',
						required: true,
					},
					{
						key: 'description',
						value: 'Gehen Sie im Hauptgebäude in die 1. Etage und durch die Feuerschutztüre. Wenn Sie sich im Gang befinden, gehen Sie durch die erste Türe auf der rechten Seite',
						required: false,
					},
					{
						key: 'sessions',
						value: '1, 3',
						required: false,
					},
				],
			},
		],
	}),

	methods:
	{
		log: console.log,

		async importUsers (data)
		{
			const headers = {};
			const url = `${this.urlBase}/${this.$route.query.eventId}/import`;

			headers['content-type'] = 'application/octet-stream';
			headers['stream-origin-file-name'] = encodeURIComponent(`${data?.file?.name}`);
			data = data.file;

			await this.$store.dispatch('request/post', {
				data,
				request: {
					path: url,
					options: {
						headers
					},
				},
			})
			.then((res) =>
			{
				if (!res) throw new Error();

				this.$emit('import-users:success');

				this.$store.commit('snackbar/show', {
					msg: ('Import erfolgreich ausgeführt.'),
					status: 'success',
				});

			})
			.catch(err =>
			{
				const response = err?.response?.data?.message || err?.response?.statusText || err?.message;
				const template = typeof response === 'string' ? 2 : 0;

				this.$store.commit('snackbar/show', {
					msg: this.$tc('common.errorOccured', template, { errorMsg: response}),
					status: 'error',
				});

				this.$emit('import-users:fail');
				this.log('IMPORT ERROR', err);
			});;

			this.initialImportData = {};
		},
	}
}
</script>
