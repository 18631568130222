<template>
	<div>
		<v-app-bar
			:color="color ?? 'primary'"
			app
			fixed
			clipped-left
		>
			<div
				class="d-flex justify-start align-center pl-1 fixedBarSpot"
			>
				<v-app-bar-nav-icon
					v-show="$vuetify.breakpoint.mdAndDown"
					color="white"
					@click="$emit('update:drawerOpen', true)"
				/>
				<img
					:src="require(`@/assets/${logo}`)"
					id="logo"
					class="mx-2 d-none d-sm-block"
					height="45"
					@click="navigate({path: '/'})"
				/>
			</div>
			
			<v-spacer/>
			
			<v-toolbar-title
				class=""
			>
				{{ title }}
			</v-toolbar-title>
			
			<v-spacer/>
			
			<div
				class="d-flex justify-end align-center pr-3 fixedBarSpot"
				id="userMenuTrigger"
			>
				<div
					v-if="$store.state.user.auth_token"
					class="d-none d-sm-block"
				>
					<div>
						{{ userName }}
					</div>
					<div
						class="userSubInformation"
					>
						{{ $store.state.user.auth_token ? $store.state.user.email : '' }}
					</div>
				</div>
				
				<DynamicAvatar
					class="ml-4"
					size="36"
					:color="$vuetify.theme.currentTheme.secondary"
					:url="userProfilePictureUrl"
					:name="userName"
				/>
			</div>
		</v-app-bar>
		
		<v-menu
			v-model="userMenuOpen"
			activator="#userMenuTrigger"
			right
			offset-y
			offset-x
			nudge-bottom="25"
			:close-on-content-click="false"
			:max-width="maximumUserMenuWidth"
			:min-width="maximumUserMenuWidth"
		>
			<v-card>
				<v-tabs
					v-model="userMenuTab"
					id="userMenuTabSelection"
				>
					<v-tab
						v-for="tab of userMenuTabs"
						:key="tab.name"
					>
						{{ tab }}
					</v-tab>
				</v-tabs>
				
				<v-tabs-items
					v-model="userMenuTab"
				>
					<v-tab-item
						key="general"
					>
						<v-list
							class="py-0"
						>
							<v-list-item
								class="pl-2"
							>
								<DynamicAvatar
									listItem
									:color="$vuetify.theme.currentTheme.secondary"
									:url="userProfilePictureUrl"
									:name="userName"
								/>
								<v-list-item-content
									class="ml-2"
								>
									<v-list-item-title>
										{{ $store.state.user.auth_token ? $store.state.user.email : '' }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-divider/>
							<v-list-item
								v-for="item in userMenuList"
								:key="item.name"
								v-show="userMenuListItemVisibility(item.name)"
								@click="item.click()"
							>
								<v-list-item-icon>
									<v-icon>{{ item.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('components.AppBar.userMenu.' + item.name) }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-tab-item>
					<v-tab-item
						key="languageSelection"
					>
						<v-list
							class="py-0"
						>
							<v-list-item>
								<v-list-item-icon>
									<v-icon
										@click="userMenuTab = 0"
									>
										mdi-arrow-left
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ $t('components.AppBar.userMenu.languageSelection') }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-divider/>
							<v-list-item
								v-for="language of $store.state.localization.languages"
								:key="language.code"
								@click="selectLanguage(language.code)"
							>
								<v-list-item-icon>
									<v-icon
										class="ml-1"
									>
										{{ language.code === $i18n.locale ? 'mdi-check' : '' }}
									</v-icon>
								</v-list-item-icon>
								<v-list-item-title
									class="d-flex flex-row"
								>
									{{ language.label }}
									<v-spacer/>
									<span
										:class="`fi fi-${language.icon ?? language.code}`"
									/>
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import DynamicAvatar from '@/components/DynamicAvatar.vue';

import VueRouterHelper from '/node_modules/@gb-net/vue-router-utility/src/vue_router_helper.js';

export default
{
	components:
	{
		DynamicAvatar
	},
	
	props:
	[
		'color',
		'title',
		'logo',
		'user',
		'hideUserMenuListItems'
	],
	
	methods:
	{
		...VueRouterHelper,
		
		userMenuListItemVisibility (name)
		{
			return !(this.hideUserMenuListItems || []).includes(name);
		},
		
		selectLanguage (locale)
		{
			this.$i18n.locale = locale;
			
			localStorage.setItem('locale', locale);
			
			this.userMenuTab = 0;
		}
	},
	
	computed:
	{
		userProfilePictureUrl ()
		{
			return this.$store.state.user?.profilePictureUniqueName ?
				this.$store.state.server.fileUrl + this.$store.state.user?.profilePictureUniqueName + '.' + this.$store.state.user?.profilePictureExtension :
				'';
		},
		
		userName ()
		{
			return this.$store.state.user?.name || [this.$store.state.user?.firstname, this.$store.state.user?.lastname].filter(string => string).join(' ');
		},
		
		maximumUserMenuWidth ()
		{
			return Math.min(this.$vuetify.breakpoint.width * 0.67, 400);
		}
	},
	
	data: (component) =>
	(
		{
			userMenuOpen: false,
			userMenuList:
			[
				{
					icon: 'mdi-account',
					name: 'profile',
					click: () =>
					{
						VueRouterHelper.navigate({path: '/profile'});
					}
				},
				{
					icon: 'mdi-translate',
					name: 'language',
					click: () =>
					{
						component.userMenuTab = 1;
					}
				},
				{
					icon: 'mdi-logout-variant',
					name: 'logout',
					click: () =>
					{
						component.userMenuOpen = false;
						
						component.$emit('logout');
					}
				}
			],
			userMenuTab: 0,
			userMenuTabs:
			[
				{
					name: 'general'
				},
				{
					name: 'languageSelection'
				}
			]
		}
	)
}
</script>

<style lang="scss" scoped>
// vuetify overrides
.v-toolbar .v-toolbar__content
{
	padding: 4px;
}

// custom classes
.fixedBarSpot
{
	width: 33%;
	max-height: 100%;
}

.userSubInformation
{
	font-size: 0.75rem;
}

#userMenuTabSelection
{
	display: none;
}

#logo
{
	padding: 8px;
}
</style>