<template lang="pug">
v-container
	.customCard
		.d-flex.justify-space-between.align-center(v-if="session?.topic")
			.d-flex
				h1.mt-1.mr-1(@click="$router.go(-1)")
					v-icon.backToPreviousPage(x-large) mdi-chevron-left
				div
					h1(
						v-if="session.subtopic"
					) {{ session.topic }} - {{ session.subtopic }}
					h1(
						v-else
					) {{ session.topic }}
					p {{ $t('pages.SessionFiles.subtitle') }}
				v-spacer
			v-btn.mx-2(
				v-show="showAdd"
				fab
				small
				depressed
				color="success"
				@click="$refs['form-add'].reset(); dialogs2.add = true")
					v-icon(dark) mdi-plus
		.mt-8(v-show="userPreferences.dialog")
			//- p The Technical Sessions (but not the workshops, symposia, the rump session and other special sessions) will be recorded and made available to all conference participants via our Virtual Venue Application. In the registration procedure for the ECOC 2024 you already agreed the recording of your contribution.
			//- p Please note, that your slides will not be shared with the public. All presentations will be deleted after the conference.
			//- p Kindly note, that you herewith submit your agreement to stream your presentation in the ECOC 2024 Virtual Venue. In case you don't wish to stream your presentation, please send us a message to ecoc2024@vde.com or contact us in the media reception.

	v-dialog(
		v-model="dialog",
		width="calc(400px + 40vw)",
		max-width="700px",
		persistent)
		v-card
			.py-3.px-4
				h2(v-if="formTitle") {{ $t(formTitle) }}
				p(v-if="formDescription") {{ $t(formDescription) }}

				#upload-bar(
					class="upload-bar hidden"
				)
					v-progress-linear(
						v-model="uploadProgress"
						color="blue-grey"
						height="24"
					)
						template(
							v-slot:default="{ value }"
						)
							strong {{ Math.ceil(value) }}%

				GenericForm(
					ref="form",
					:format="format",
					:appearances="appearances",
					:initialData="initialData",
					@validation:success="submit($event)"
				)

				template(
					v-if="(databaseOperation = dialogs[currentItem?.databaseOperation])")
						v-card-actions
							v-spacer

							v-btn.grey.white--text(
								v-if="databaseOperation?.buttons?.cancel",
								@click="cancel()"
							) {{ $t('common.cancel') }}
							v-btn.red.white--text(
								v-if="databaseOperation?.buttons?.delete",
								@click="_delete()"
							) {{ $t('common.delete') }}
							v-btn.green.white--text(
								ref="submit"
								v-if="databaseOperation?.buttons?.confirm",
								@click="$refs.form.validate()"
							) {{ $t('common.confirm') }}

	v-dialog(
		v-model="dialogs2.add"
		max-width="700"
		eager
	)
		v-card
			v-card-title.pb-4
				h2(v-if="dialogs.create") {{ $t(dialogs.create.title) }}
				p(
					v-if="dialogs.create"
					style="font-size: smaller;"
				) {{ $t(dialogs.create.description) }}

			v-card-text.pb-0
				GenericForm(
					ref="form-add"
					horizontalLayoutGap="24px"
					verticalLayoutGap="0px"
					compensateContainerGapHorizontally
					:format="formats.create"
					:appearances="appearances"
					:initialData="initialDataCreate",
					@validation:failure="$emit('add-validation:failure', $event); log($event)"
					@validation:success="log($event, formats.create);submit2($event)"
				)
			v-card-actions
				v-spacer
				v-btn(
					color="cancel"
					class="white--text"
					@click="dialogs2.add = false; $refs['form-add'].resetValidation()"
				) {{ $t('common.cancel') }}
				v-btn(
					color="save"
					class="white--text"
					@click="$refs['form-add'].validate()"
				) {{ $t('common.save') }}

	OverviewGrid(
		:items="items"
		:tableActions="tableActions"
		:tableItems="tableItems"
		:show="itemsLoaded"
		@quickSetFilestatus="quickSetFilestatus"
		@toggleUpdate="toggleUpdate",
		@toggleExport="download",
		@toggleDelete="toggleDelete",
		@toggleFileUpload="toggleFileUpload")
</template>

<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';
import OverviewGrid from '@/components/Overview/OverviewGrid.vue';
import FormHelper from '@/customModules/libraries/form.helper.js';

export default
{
	name: 'SessionFiles',
	components:
	{
		ObjectManagementList: require('@/components/ObjectManagementList.vue').default,
		GenericForm: require('/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue').default,
		OverviewGrid,
	},
	async created ()
	{
		await this.initialize();

		/* fetch(
			process.env.NODE_ENV === 'development' ? 'http://localhost/slide center/server_user_preferences/request.php' : 'https://ecoc2024.gb-interactive.com/request.php',
			{
				method: 'POST',
				body: JSON.stringify(
					{
						name: 'preferences',
						action: 'meta'
					}
				)
			}
		).then(async (response) =>
		{
			const data = await response.json();
			
			this.userPreferences.format = data.format;console.log(data.format)
		}).catch(error => console.log(error)) */
	},
	methods:
	{
		request: RequestHandler.request,
		
		async initialize () 
		{
			return new Promise((resolve) => 
			{
				RequestHandler.requests(this.requests.initialize, this)
				.finally(() =>
				{
					this.formats.create.push(
						{
							name: 'event',
							type: 'string',
							required: true
						}
					);
					
					this.formats.update.push(
						{
							name: 'event',
							type: 'string',
							required: true
						}
					);
					
					this.appearances.push(
						{
							name: 'type',
							label: this.$t('pages.SessionFiles.format'),
							labels: this.formats.create.filter(field => field.name === 'type')?.[0].values.map(value => this.$t(`pages.SessionFiles.type.${value}`))
						},
					);
					
					this.formats.create = FormHelper.restrictFormatsToEvent(this.formats.create, this.$route.query.eventId);
					this.formats.create = FormHelper.restrictFormatsToSession(this.formats.create, this.$route.query.sessionId);
					this.formats.create = this.restrictToSessionSpeakers(this.formats.create, this.session);
					
					if (this.session?.speakers?.length === 1)
					{
						// we prefill the speaker, because there is no alternative
						this.initialDataCreate.owner = this.session.speakers[0]._id;
					}
					else
					{
						delete this.initialDataCreate.owner;
					}
					// we have to check grants because when logged in as speaker, just after admin login, the speaker will see other files (from store?)
					if (!this.$store.getters['authorization/userCan']('read', 'files', 'any'))
					{
						this.items = this.items.filter(e => e.owner._id === this.user._id);
					}
					this.itemsLoaded = true;
					
					
					
					this.userPreferences.initialData.session = this.session.displayname;
					this.userPreferences.initialData.speaker = this.$store.state.user.displayname;
					this.userPreferences.initialData.startTime = this.session.start;
					
					RequestHandler.request(
						{
							store: 'rooms',
							axios:
							{
								url: `rooms`,
							},
						},
					).then((response) =>
					{
						this.room = response.data.filter(room => room.sessions.filter(session => session?._id === this.session?._id).length > 0)[0];
						
						this.userPreferences.initialData.roomId = this.room?._id;
						this.userPreferences.initialData.room = this.room?.displayname;
						
						// trigger reactivity for object
						this.userPreferences.initialData = Object.assign({}, this.userPreferences.initialData);
						
						return resolve(true);
					}).catch(() => 
					{ 
						return resolve(false) 
					});
				});
			});
		},

		log: console.log,
		setCurrentItem (databaseOperation, formSubmittingRoute, formMethod = 'request/get')
		{
			this.currentItem = {
				databaseOperation,
				formSubmittingRoute: `${this.urlBase}${formSubmittingRoute}`,
				formMethod,
			};
		},
		isAllowed (action)
		{

			if (typeof action === 'boolean')
			{
				return action;
			}

			if (Array.isArray(action))
			{
				return action.includes(this?.user?.role);
			}

			return false;
		},
		/**
		 * restrict formdata to session speakers only
		 */
		restrictToSessionSpeakers (formats)
		{
			if (!this.session?.speakers || !Array.isArray(formats))
			{
				return;
			}

			return formats.map(e =>
			{
				if (e?.name !== 'owner')
				{
					return e;
				}

				for (const [key, value] of Object.entries(e.values))
				{
					const speaker = this.session.speakers.find(s => `${s._id}` === key);

					if (!speaker)
					{
						delete e.values[key];
					}
				}

				return e;
			});
		},
		getFormData (_path, databaseOperation)
		{
			this.$store.dispatch('formfields/get', {
				path: _path,
				params: {
					action: databaseOperation,
					event: this.$route.query.eventId,
					session: this.$route.query.sessionId,
					resource: this.urlBase,
				},
			}).then(res =>
			{
				this.format = res;

				if (databaseOperation === 'update')
				{
					this.format.map((field) =>
					{
						if (field.name === 'password') field.required = false;

						return field;
					});
				}
				
				this.format = FormHelper.restrictFormatsToEvent(this.format, this.$route.query.eventId);
				this.format = FormHelper.restrictFormatsToSession(this.format, this.$route.query.sessionId);
				this.format = this.restrictToSessionSpeakers(this.format);

				this.prefillFormWithData(databaseOperation, `${this.urlBase}/${this.item?._id}`);
			});
		},

		getData ()
		{
			this.$store.dispatch('request/get', {
				request: {
					path: this.urlBase,
				},
			})
				.then(items =>
				{
					return this.view.table.items = items;
				})
				.catch(err => console.log('getData.error', err))
				.finally(() => this.dataRequestFinished = true);
		},

		prefillFormWithData (databaseOperation, path)
		{
			if (databaseOperation === 'update')
			{
				this.$store.dispatch('request/get', {
					request: {
						path,
					},
				})
					.then(prefill =>
					{
						Object.entries(prefill).forEach(([key, value]) =>
						{
							const field = this.format.find(field => field.name === key);

							if (!field)
							{
								return;
							}

							if (field.values)
							{
								if (value instanceof Array && value.some(item => typeof item === 'object'))
								{
									prefill[key] = value.map(item => item._id) ?? value;

									return;
								}

								if (!(value instanceof Array) && typeof value === 'object' && value !== null)
								{
									prefill[key] = value._id;

									return;
								}
							}

							prefill[key] = value;
						});
						if (this.session?.speakers?.length === 1)
						{
							prefill.owner = this.session.speakers[0]._id;
						}

						this.initialData = prefill;
						const name = prefill?.name || prefill?.title || prefill?.topic;

						this.formTitle = `${this.formTitle}`.replace('[X]', name);
					})
					.catch(err => console.log('prefillFormWithData.error', err));
			}
			/* else
			{
				Object.entries(this.initialData).forEach(([value, key]) =>
				{
					this.initialData[key] = null;
				});
				setTimeout(() => this.initialData = {}, 1000);
				console.log(this.initialData)
			}
			this.$forceUpdate();
			 */
		},
		
		toggleDialog ()
		{
			this.dialog = !this.dialog;

			this.initialData = {};
		},

		setDialogFormTitleDescription (databaseOperation)
		{
			const form = this.dialogs?.[databaseOperation];

			this.formTitle = form?.title;
			this.formDescription = form?.description;
		},


		// TODO: this action does not have a formfield-template!
		toggleImport (databaseOperation = 'import')
		{
			// this.getFormData(databaseOperation);
			// this.setDialogFormTitleDescription(databaseOperation);

			// this.toggleDialog();
		},

		download (_item)
		{
			// if (this.items.path === 'files')
			// {
				//return window.location.href = this.$store.state.request.url + `/${this.items.path}/download/${_item._id}`;
				return window.open(
					this.$store.state.request.url + `${this.urlBase}/download/${_item._id}?token=${this.$store.state.user.auth_token}`,
					'_blank'
				);
			// }

			window.open(
				this.$store.state.request.url + `/${this.urlBase}/${_item._id}/export?token=${this.$store.state.user.auth_token}`,
				'_blank'
			);
		},

		toggleFileUpload (item)
		{
			this.setCurrentItem(
				'upload',
				'/upload/' + item._id,
				'request/post'
			);

			this.format = [
				{
					name: 'file',
					type: 'file',
					required: false,
				},
			];

			this.setDialogFormTitleDescription('upload');
			this.toggleDialog();
		},

		toggleDelete (_item, databaseOperation = 'delete')
		{
			this.showForm = false;
			this.format = [];
			this.item = _item;
			const _id = _item?._id;

			this.setCurrentItem(databaseOperation, `/${_id}`, 'request/delete');
			this.setDialogFormTitleDescription(databaseOperation);

			this.toggleDialog();
		},

		quickSetFilestatus (_item, _newFilestatus)
		{
			const fileID = _item._id;

			_item.status = _newFilestatus;

			RequestHandler.request(this.requests.updateFilestatus, {
				url: `${this.requests.updateFilestatus.axios.url}/${fileID}`,
				body: _item,
			});
		},

		_delete ()
		{
			const { formMethod, formSubmittingRoute } = this.currentItem;

			this.submit({}, formMethod, formSubmittingRoute);
		},


		toggleUpdate (_item)
		{
			const databaseOperation = 'update';
			const pathCategory = 'files';

			this.item = _item;
			const _id = _item?._id;

			this.setCurrentItem(databaseOperation, `/${_id}`, 'request/put');

			this.getFormData(pathCategory, databaseOperation);
			this.setDialogFormTitleDescription(databaseOperation);

			this.toggleDialog();
		},

		cancel ()
		{
			if (this.dialogUnsavedChanges) return;
			this.toggleDialog();
		},

		submit (data)
		{

			const { databaseOperation, formMethod, formSubmittingRoute } = this?.currentItem;
			const headers = {};
			const isFileUpload = this.format.length === 1 && this.format[0].type === 'file';
			const barElement = document.getElementById('upload-bar');
			const formElement = document.getElementsByClassName('genericForm')[1];

			if (isFileUpload)
			{
				headers['content-type'] = 'application/octet-stream';
				headers['stream-origin-file-name'] = encodeURIComponent(`${data?.file?.name}`);

				data = data[this.format[0].name];
				this.uploadProgress = 0;
				
				if (barElement.classList.contains('hidden'))
				{
					barElement.classList.remove('hidden');
					formElement.classList.add('hidden');
					this.$refs['submit'].$el.classList.add('hidden');		
				}
			}

			this.$store.dispatch(formMethod, {
				data,
				request: {
					path: formSubmittingRoute,
					options: {
						headers,
						onUploadProgress: event => {
							this.uploadProgress = Math.round((event.loaded * 100) / event.total);
						}
					},
				},
			})
				.then(async res =>
				{
					if(!res) throw new Error();

					this.$store.commit('snackbar/show', {
						msg: this.$t(this.snackbar[databaseOperation]?.success ?? 'Operation erfolgreich ausgeführt.').replace('[X]', this.item.title),
						status: 'success',
					});

					// RequestHandler.requests(this.requests.initialize, this);
					await this.initialize();

					this.resetForm();
				})
				.catch(err =>
				{
					const response = err?.response?.data?.message || err?.response?.statusText || err?.message;
					const template = typeof response === 'string' ? 2 : 0;

					this.$store.commit('snackbar/show', {
						msg: this.$tc(this.snackbar[databaseOperation]?.fail, template, { errorMsg: response }),
						status: 'error',
					});
					console.log('REQUEST ERROR', err);
				});
		},

		submit2 (event)
		{
			const self = this;

			const data =
			{
				url: `files`,
				body: event
			};

			RequestHandler.request(this.requests.create, data)
			.then(function ()
			{
				// RequestHandler.requests(self.requests.initialize, self);
				self.initialize();
			});
			this.dialogs2.add = false;
		},

		resetForm ()
		{
			this.getData();

			this.toggleDialog();

			this.initialData = {};

			this.item = {};
			this.currentItem = {};
		},
		
		savePreferences (data)
		{
			this.userPreferences.loading = true;
			
			console.log(data);
			
			fetch(
				process.env.NODE_ENV === 'development' ? 'http://localhost/slide center/server_user_preferences/request.php' : 'http://localhost/slide center/server_user_preferences/request.php',
				{
					method: 'POST',
					body: JSON.stringify(
						{
							name: 'preferences',
							action: 'add',
							object: data
						}
					)
				}
			).then(async (response) =>
			{
				const data = await response.json();
				
				if (!data.success)
				{
					throw data.text;
				}
				
				this.userPreferences.dialog = false;
			}).catch(error => console.log('error', error)).finally(() =>
			{
				this.userPreferences.loading = false;
				
				localStorage.setItem('userPreferencesSet_' + this.$route.query.sessionId, true);
			});
		},
	},
	watch:
	{
		dialog (value) 
		{
			const barElement = document.getElementById('upload-bar');
			const formElement = document.getElementsByClassName('genericForm')[1];
			const submitButton = this.$refs['submit']?.$el;

			if (!value)
			{
				barElement.classList.add('hidden');
				formElement.classList.remove('hidden');
				if (submitButton)
				{
					submitButton.classList.remove('hidden');		
				}
			}
		}
	},
	computed:
	{
		user ()
		{
			return this.$store.state.user;
		},
		tableHeaders ()
		{
			return this.view.table.headers;
		},
		tableItems ()
		{
			return this.view.table.items;
		},
		tableActions ()
		{
			return {
				update: this.rolesAllowedForFileEdit,
				delete: true,
				export: true
			};
		},
		requests ()
		{
			return {
				create:
				{
					axios:
					{
						url: this.urlBase,
						method: 'post',
					},
				},
				update:
				{
					axios:
					{
						url: this.urlBase,
						method: 'put',
					},
				},

				updateFilestatus:
				{
					axios:
					{
						url: this.urlBase,
						method: 'put',
					},
				},

				// delete:
				// {
				// 	axios:
				// 	{
				// 		url: this.urlBase,
				// 		method: 'delete',
				// 	},
				// },
				initialize:
				[
					{
						store: 'formats.create',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'create',
								event: this.$route.query.eventId,
								session: this.$route.query.sessionId,
							},
						},
					},
					{
						store: 'formats.update',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'update',
								event: this.$route.query.eventId,
								session: this.$route.query.sessionId,
							},
						},
					},
					{
						store: 'session',
						axios:
						{
							url: `sessions/${this.$route.query.sessionId}`,
						},
					},
					{
						store: 'items',
						axios:
						{
							url: `${this.urlBase}`,
							params:
							{
								session: this.$route.query.sessionId,
								limit: (this.session?.files?.length || 99) + 1
							},
						},
					},
				],
			}
		},
	},
	
	data: (component) =>
	({
		uploadProgress: 0,
		userPreferences:
		{
			loading: false,
			// dialog: !localStorage.getItem('userPreferencesSet_' + component.$route.query.sessionId) && component.$store.state.user.role === 'speaker',
			dialog: false,
			initialData:
			{
				roomId: null,
				sessionId: component.$route.query.sessionId,
				speakerId: component.$store.state.user._id,
				room: null,
				session: null,
				speaker: null,
				startTime: null,
				refuseRecording: false
			},
			format: [],
			appearances:
			[
				{
					name: 'roomId',
					hidden: true
				},
				{
					name: 'sessionId',
					hidden: true
				},
				{
					name: 'speakerId',
					hidden: true
				},
				{
					name: 'room',
					hidden: true
				},
				{
					name: 'session',
					hidden: true
				},
				{
					name: 'speaker',
					hidden: true
				},
				{
					name: 'startTime',
					hidden: true
				},
				{
					name: 'refuseRecording',
					label: 'Refuse video recording'
				}
			]
		},
		
		itemsLoaded: false,

		// COPIED FROM ObjectManagementList (27.06.2024)
		showAdd: true,

		dialog: false,
		dialogUnsavedChanges: false,
		dataRequestFinished: false,

		// Josua Formgenerator
		showForm: true,
		formTitle: '',
		formDescription: '',
		format: [],
		appearances: [
			{
				name: 'name',
				label: component.$t('pages.SessionFiles.filename')
			},
			{
				name: 'file',
				label: component.$t('pages.SessionFiles.upload'),
			},
			{
				name: 'owner',
				label: component.$t('pages.SessionFiles.owner')
			},
			{
				name: 'session',
				label: component.$t('pages.SessionFiles.session'),
				hidden: true
			},
			{
				name: 'event',
				label: component.$t('pages.SessionFiles.event'),
				hidden: true
			},
			{
				name: 'status',
				label: component.$t('pages.SessionFiles.status')
			},
		],
		initialData: {},
		initialDataCreate:
		{
			event: component.$route.query.eventId,
			session: component.$route.query.sessionId,
		},

		item: {},

		currentItem: {
			databaseOperation: '', // create ---- RENAME: databaseOperation ||
			formSubmittingRoute: '', // /rooms/a80b-f83
			formMethod: '', // request/delete
		},


		urlBase: 'files',
		session: {},
		items: [],
		formats: {create: [], update: []},


		commonActions: {
			create: false,
			import: false,
			export: false,
		},
		view: {
			type: 'grid',
			columnWidth: '6',
			useSearch: true,
			table: {
				items: [],
				actions: {
					update: component.rolesAllowedForFileEdit,
					delete: true,
					export: true,
				},
			},
		},

		snackbar: {
			create: {
				success: 'objects.snackbar.create.success',
				fail: 'objects.snackbar.create.fail',
			},
			update: {
				success: 'objects.snackbar.update.success',
				fail: 'objects.snackbar.update.fail',
			},
			delete: {
				success: 'objects.snackbar.delete.success',
				fail: 'objects.snackbar.delete.fail',
			},
			upload: {
				success: 'objects.snackbar.upload.success',
				fail: 'objects.snackbar.upload.fail',
			},
		},
		dialogs: {
			create: {
				title: 'objects.dialogs.presentation.create.title',
				description: 'objects.dialogs.presentation.create.description',
				buttons: {
					cancel: true,
					confirm: true,
				},
			},
			delete: {
				title: 'objects.dialogs.presentation.delete.title',
				description: 'objects.dialogs.presentation.delete.description',
				buttons: {
					cancel: true,
					// confirm: true,
					delete: true,
				},
			},
			update: {
				title: 'objects.dialogs.presentation.update.title',
				description: 'objects.dialogs.presentation.update.description',
				buttons: {
					cancel: true,
					confirm: true,
				},
			},
			upload: {
				title: 'objects.dialogs.presentation.upload.title',
				description: 'objects.dialogs.presentation.upload.description',
				buttons: {
					cancel: true,
					confirm: true,
				},
			},
		},

		// <!-- * DYNAMIC VARIABLES FROM Session.vue -->
		dialogs2:
		{
			add: false,
			update: false,
			delete: false,
		},

		rolesAllowedForFileEdit: 
		[
			'admin', 'speaker', 'operator', 'event-admin'
		]
	}),
}
</script>

<style lang="scss" scoped>
.hidden
{
	display: none;
}
.upload-bar
{
	margin-bottom: 20px;
}
</style>