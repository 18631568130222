<template lang="pug">
v-footer(app)
	v-container.d-flex.flex-column.justify-space-between.align-center
		.d-flex.justify-space-around.mb-5
			a.action.no-link(
				v-for="(item, i) in footerLinks",
				:href="item.link",
				:key="i"
			) {{ item.text }}
		a.no-link.secondary--text.text-h6.text-center(:href="companyWebsite") {{ companyName }}
</template>

<script>
export default {
	name: 'Footer',
	props: [
		'footerLinks',
		'companyName',
		'companyWebsite',
	]
}
</script>


<style lang="sass" scoped>
div
	.action
		background-color: white
		border-radius: 9px
		box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .3)
		padding: 6px 12px
		font-weight: 500
		transition: 170ms ease
		&:hover
			color: #e30054
</style>
