<template lang="pug">
.customCard.mt-8
	v-card-title
		h2.myTitle {{ header }}
	v-data-table.transparent(
		:headers="headers"
		:items="actions"
		:options.sync="actionsOptions"
		:items-per-page="parseInt(itemsPerPage)"
		:server-items-length="actionsTotal"
		:loading="actionsLoading"
		:footer-props="{'items-per-page-options': [5, 10, 15, -1], itemsPerPageText: $t('Project.table.rowsPerPage')}"
	)
		template(
			v-slot:item.createdAt="{ item }"
		)
			span {{ new Date(item.createdAt).toLocaleString($i18n.locale) }}
		template(
			v-slot:item.resource="{ item }"
		)
			span {{ translate(['resource', item.resource], item.resource) }}
		template(
			v-slot:item.action="{ item }"
		)
			span {{ translate(['action', item.action], item.action) }}
		template(
			v-slot:item.event="{ item }"
		)
			span {{ item.event?.length ? item.event : `- ${$t('Project.table.noExistingData')} -` }}
		template(
			v-slot:item.data="{ item }"
		)
			a(
				href="#"
				@click="data = item.data; dataHeaderItem = item.resource; dataHeaderAction = item.action; showData = true;"
			) {{ item.data.length }} {{ $tc('Project.table.dataset', 2) }}
		template(
			v-slot:footer.page-text="items"
		) {{ items.pageStart }} - {{ items.pageStop }} {{ $t('common.of') }} {{ items.itemsLength }}

	v-dialog(
		v-model="showData"
		max-width="700"
		eager
		scrollable
	)
		v-card
			v-card-title(
				class="pb-4"
			)
				| {{ $tc('Project.table.dataset', 2) }}
				small &nbsp;({{ translate(['resource', dataHeaderItem], dataHeaderItem) }} {{ translate(['action', dataHeaderAction], dataHeaderAction) }})

			v-card-text(
				class="pb-0"
			)
				div(
						v-for="item, i of data"
						:key="item._id"
					)
					v-row
						v-col(
							cols="1"
						) {{ i+1 }}.
						v-col {{ item.displayname }}
			v-card-actions
				v-spacer
				v-btn(
					@click="showData = false; data = []"
				)
					| {{ $t('common.ok') }}
</template>

<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';
import Utility from '/node_modules/@gb-net/utility/utility.js';

export default
{
	name: 'ActionLogs',
	props:
	[
		'header',
		'itemsPerPage'
	],
	created ()
	{
	},
	methods:
	{
		loadActions ()
		{
			this.actionsLoading = true;
			const { sortBy, sortDesc, page, itemsPerPage } = this.actionsOptions;
			const descending = sortDesc?.length && sortDesc[0] === true ? '-' : '';
			const sort = sortBy?.length ? `&sort=${descending}${sortBy[0]}` : '';
			const event = this.eventId ? `&event=${this.eventId}` : '';

			RequestHandler.request(
			{
				store: 'actions',
				axios:
				{
					url: `action-logs/?page=${page}&perPage=${itemsPerPage < 0 ? 10000 : itemsPerPage}${sort}${event}`
				}
			}, null, this)
			.then((response) =>
			{
				const docCountFromHeader = response.headers.get('pagination-documents');

				this.actionsTotal = parseInt(docCountFromHeader);
				this.actionsLoading = false;
			});
		},
		translate (path, alternative)
		{
			const value = Utility.object.resolveValue(this.actionsTranslations, path);

			return value ?? alternative;
		}
	},
	computed:
	{
		eventId (component)
		{
			return component.$route.query.eventId;
		},
		headers ()
		{
			if (this.eventId)
			{
				return this.actionsHeaders.filter(e => e.value !== 'event');
			}
			return this.actionsHeaders.filter(e => e.value !== 'data');
		}
	},
	data: (component) =>
	({
		actions: [],
		actionsOptions: {},
		actionsTotal: 0,
		actionsLoading: true,
		actionsHeaders:
		[
			{
				text: component.$t('pages.ActionLogs.createdAt'),
				value: 'createdAt',
				sortable: true
			},
			{
				text: component.$t('pages.ActionLogs.resource'),
				value: 'resource',
				sortable: true
			},
			{
				text: component.$t('pages.ActionLogs.action'),
				value: 'action',
				sortable: true
			},
			{
				text: component.$t('pages.ActionLogs.event'),
				value: 'event',
				sortable: true
			},
			{
				text: component.$t('pages.ActionLogs.user'),
				value: 'user',
				sortable: true
			},
			{
				text: component.$t('pages.ActionLogs.data'),
				value: 'data',
				sortable: false
			}
		],
		actionsTranslations:
		{
			'resource':
			{
				users: component.$t('pages.ActionLogs.users'),
				settings: component.$t('pages.ActionLogs.settings'),
				sessions: component.$t('pages.ActionLogs.sessions'),
				events: component.$t('pages.ActionLogs.events'),
				rooms: component.$t('pages.ActionLogs.rooms'),
				files: component.$t('pages.ActionLogs.files'),
				'mailing.invitation': component.$t('pages.ActionLogs.mailingInvitation'),
				'mailing.reminder-upload': component.$t('pages.ActionLogs.mailingReminderUpload'),
				'mailing.notification-upload': component.$t('pages.ActionLogs.mailingNotificationUpload'),
				'mailing.acknowledgement': component.$t('pages.ActionLogs.mailingAcknowledgement'),
			},
			'action':
			{
				create: component.$t('pages.ActionLogs.create'),
				update: component.$t('pages.ActionLogs.update'),
				delete: component.$t('pages.ActionLogs.delete'),
				upload: component.$t('pages.ActionLogs.upload'),
				download: component.$t('pages.ActionLogs.download'),
				export: component.$t('pages.ActionLogs.export'),
				import: component.$t('pages.ActionLogs.import'),
				send: component.$t('pages.ActionLogs.send'),
			}
		},
		showData: false,
		data:[],
		dataHeaderItem: '',
		dataHeaderAction: ''
	}),
	watch: {
		actionsOptions: {
			handler () {
				this.loadActions()
			},
			deep: true,
		}
	}
}
</script>