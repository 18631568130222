/**
 * @file Configuration module for parsing environment based values with special method
 * @author Josua Todebusch
 * @version 1.0.0
 */

import configuration from '@/configuration.json';

export default
{
	fallback: 'development',
	
	getEnvironmentalValue (key)
	{
		return configuration.environment[process.env.NODE_ENV]?.[key] ?? configuration.environment[this.fallback]?.[key];
	}
};