<template lang="pug">
v-container
	ObjectManagementList(
		:items="items"
		:formats="formats"
		:initialDataCreateForm="initialDataCreateForm"
		:appearances="appearances"
		:tableHeaders="tableHeaders"
		:dataTableOptions.sync="dataTableOptions"
		:loading.sync="loading"
		:maximumItemCount="maximumItemCount"
		:textAdd="{ header: $t('objects.session.create.title'), text: $t('objects.session.create.description') }"
		:textUpdate="{ header: $t('objects.session.edit.title'), text: $t('objects.session.edit.description') }"
		:textDelete="{ header: $t('objects.session.delete.title'), html: $t('objects.session.delete.description') }"
		:rules="$store.state.validation.rules"
		:ruleMessages="$store.state.validation.ruleMessages"
		:actionColumnLabel="$t('common.actions')"
		@search="onSearch"
		@add-validation:success="submit(requests.create, {body: $event});log($event);"
		@update-validation:success="submit(requests.update, {url: requests.delete.axios.url + '/' + $event._id, body: $event});"
		@delete:confirmation="submit(requests.delete, {url: requests.delete.axios.url + '/' + $event._id});"
		@update:dataTableOptions="log('update:dataTableOptions', $event); updateItems()"
		:showUpdate="Array.isArray(formats?.update) && formats.update.length > 0"
		:showDelete="Array.isArray(formats?.create) && formats.create.length > 0"
	)
		template(#header)
			div
				div
					h1.myTitle {{ $t('objects.session.title') }}
					p.mb-0 {{ $t('objects.session.description') }}

				v-divider.mt-7.mb-3
				.legend.rounded-xxl
					h4.mb-1 {{ $t('pages.Session.fileStatusTitle') }}
					div.d-flex
						h6.white--text.px-4.py-1.rounded-pill.mr-1(v-for="(item, i) in $store.state.presentations.statusColors"
							style="width: fit-content; padding-block: 2px;"
							:class="item.color") {{ $t(`pages.Session.fileStatus.${item.color}`) }}
				v-divider.mt-3.mb-7

		template(#item.filesCount="item")
			div {{ item.files?.length ?? 0 }}
		template(#item.files="item")
			.d-flex(
				v-if="item.files?.length"
				style="height: 50%"
			)
				div.flex-grow-1(
					v-for="file of item.files"
					:key="file._id"
					:class="`${$store.state.presentations.statusColors?.[file.status].color ?? 'error'}`"
				)
			div(
				v-else
			) {{ $t('Project.common.noElementAssigned', { element: item.speakers?.length ? $tc('objects.file', 2) : $tc('objects.speaker', 2)}) }}

		template(#item.start="item")
			div {{ new Date(item.start).toLocaleString($i18n.locale) }}
		template(#item.end="item")
			div {{ new Date(item.end).toLocaleString($i18n.locale) }}
		template(#item.filesUpdatedAt="item")
			div {{ new Date(item.filesUpdatedAt).toLocaleString($i18n.locale) }}
		template(#item.actions.extra="{ item }")
			v-btn.mr-2(
				v-show="$store.getters['authorization/userCan']('view', 'files')"
				color="secondary"
				fab
				depressed
				x-small
			)
				v-icon mdi-file-eye

			v-btn.mr-2(
				v-show="$store.getters['authorization/userCan']('read', urlBase, 'any') || ($store.getters['authorization/userCan']('read', urlBase, 'own') && $store.state.user?._events?.includes(item.event) || $store.state.user?._events?.length === 0)"
				color="grey"
				fab
				depressed
				x-small
				@click="$router.push({ path: '/sessionfiles', query: { sessionId: item._id, eventId: item.event } })"
			)
				v-icon.white--text mdi-cursor-default-click-outline

</template>

<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';
import FormHelper from '@/customModules/libraries/form.helper.js';

export default
{
	name: 'Presentations',
	components:
	{
		ObjectManagementList: require('@/components/ObjectManagementList.vue').default,
	},
	created ()
	{
		RequestHandler.requests(this.requests.initialize, this)
		.catch(() => 
		{});;
	},
	methods:
	{
		log: console.log,

		request: RequestHandler.request,

		submit (request, data)
		{
			const self = this;

			this.request(request, data)
			.then(function ()
			{
				self.updateItems();
			})
		},
		updateItems ()
		{
			// prevent the watcher from triggering itself
			if (this.loading)
			{
				return;
			}

			this.loading = true;

			RequestHandler.request(
				this.requests.get,
				null,
				this,
				Object.assign(this.dataTableOptions, { search: this.search })
			).then((response) =>
			{
				this.maximumItemCount = parseInt(response.headers.get('pagination-documents'));
				// we have to map speaker id's because generic form is not able to handle speaker objects
				this.items = this.items.map(i =>
				{
					i.speakers = i.speakers.map(s => s._id);

					return i;
				});
				this.formats.create = FormHelper.restrictFormatsToEvent(this.formats.create, this.$route.query.eventId);
				this.formats.update = FormHelper.restrictFormatsToEvent(this.formats.update, this.$route.query.eventId);

				setTimeout(() => {this.loading = false}, 0);
			});
		},
		
		onSearch (search)
		{
			if (this.loading)
			{
				return;
			}

			if (search !== this.search)
			{
				this.dataTableOptions.page = 1;
			}
			this.search = search;

			return this.updateItems();
		},

		mergeFormat: function ()
		{
			const additionalFields =
			[
				{
					name: 'filesUpdatedAt',
					type: 'datetime',
					required: null
				}
			];
			
			if (typeof this.formats?.create === 'object') this.formats.create.concat(additionalFields);
			if (typeof this.formats?.update === 'object')this.formats.update.concat(additionalFields);
			
			const additionalValues =
			{
				topic:
				{
					minimumLength: 2,
					maximumLength: 128
				},
				subtopic:
				{
					minimumLength: 0,
					maximumLength: 1024
				}
			};
			if (typeof this.formats?.create !== 'object')
			{
				return;
			}

			for (const field of this.formats.create)
			{
				if (additionalValues[field.name])
				{
					for (const [key, value] of Object.entries(additionalValues[field.name]))
					{
						field[key] = value;
					}
				}
			}
		},
	},
	
	// temporary: values should come from server in the future instead
	watch:
	{
		'formats.create': function () {this.mergeFormat();},
		'formats.update': function () {this.mergeFormat();}
	},
	
	computed:
	{
		requests ()
		{
			return {
				get:
				{
					store: 'items',
					axios:
					{
						url: this.urlBase,
						params:
						{
							event: this.$route.query.eventId,
						}
					}
				},
				create:
				{
					axios:
					{
						url: this.urlBase,
						method: 'post'
					}
				},
				update:
				{
					axios:
					{
						url: this.urlBase,
						method: 'put'
					}
				},
				delete:
				{
					axios:
					{
						url: this.urlBase,
						method: 'delete'
					}
				},
				initialize:
				[
					{
						store: 'formats.create',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'create',
								event: this.$route.query.eventId,
							}
						}
					},
					{
						store: 'formats.update',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'update',
								event: this.$route.query.eventId,
							}
						}
					},
				]
			}
		}
	},
	data: (component) =>
	({
		urlBase: 'sessions',
		items: [],
		formats: {},
		tableHeaders:
		[
			'topic',
			'subtopic',
			'filesCount',
			'files',
			'filesUpdatedAt',
			//'start',
			//'end',
		],

		dialog: false,

		appearances:
		[
			{
				name: 'topic',
				label: component.$t('objects.session.properties.topic')
			},
			{
				name: 'subtopic',
				label: component.$t('objects.session.properties.subtopic')
			},
			{
				name: 'room',
				label: component.$t('objects.session.properties.room')
			},
			{
				name: 'filesCount',
				label: component.$t('objects.session.properties.filesCount')
			},
			{
				name: 'files',
				label: component.$t('objects.session.properties.files')
			},
			{
				name: 'speakers',
				label: component.$t('objects.session.properties.speakers')
			},
			{
				name: 'event',
				label: component.$t('objects.session.properties.event')
				//hidden: true
			},
			{
				name: 'filesUpdatedAt',
				label: component.$t('common.updatedAt')
			},
			{
				name: 'start',
				label: component.$t('Project.Dates.start.time')
			},
			{
				name: 'end',
				label: component.$t('Project.Dates.end.time')
			}
		],
		initialDataCreateForm:
		{
			event: component.$route.query.eventId,
		},

		loading: false,
		search: '',
		dataTableOptions: {},//{itemsPerPage: 5},
		maximumItemCount: 0
	}),
}
</script>