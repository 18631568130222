import Vue from 'vue';
import VueRouter from 'vue-router';
import VueRouterInitializationHelper from '/node_modules/@gb-net/vue-router-utility/src/vue_router_initialization_helper.js';

import Store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter(
	{
		mode: 'history',
		base: process.env.BASE_URL,
		routes:
		[
			{
				path: '*',
				redirect: '/404'
			},
			{
				path: '/',
				redirect: '/home'
			},

			...VueRouterInitializationHelper.generateStaticRoutes(
				require.context(`@/pages`, true, /\.vue$/, 'lazy')
			),

			{
				name: 'SessionFiles',
				path: '/sessionfiles/:id',
				component: () => import('../pages/SessionFiles.vue'),
			},
		],
	}
);

function clearLocalStorageAndRedirectToLogin (next)
{
	localStorage.removeItem('auth_token');

	if (router.currentRoute.path === '/user/login')
	{
		return next();
	}

	next({ path: '/user/login' });
}

router.beforeEach((to, from, next) =>
{
	if (to.path === '/user/login')
	{
		if (router.currentRoute.path === '/user/login')
		{
			return;
		}

		return next();
	}

	if (!Store.state.user.auth_token)
	{
		return clearLocalStorageAndRedirectToLogin(next);
	}

	Store.dispatch('user/getMe')
		.then(res =>
		{
			Store.dispatch('user/getGrants');
			next();
		}).catch(err =>
		{
			clearLocalStorageAndRedirectToLogin(next);
		});
});

export default router;