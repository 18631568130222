<template lang="pug">
v-container
	div.customCard
		h1.myTitle {{ $tc('Portal.settings.title', 2) }}
		p {{ $t('Portal.settings.subtitle') }}.
		div
			v-switch(
				v-model="allowUpload"
				:label="$t('Project.presentations.allow')"
			)
			div.mb-6 {{ $t('Project.presentations.description1') }}.
			b {{ $t('Project.presentations.uploadTimeRange') }}
			GenericForm.mt-6(
				ref="form-timestamps"
				horizontalLayoutGap="24px"
				verticalLayoutGap="0px"
				compensateContainerGapHorizontally
				:format="timestampsFormat"
				:appearances="timestampsAppearances"
				:initialData="timestampsInitialData"
				@validation:failure="log('validation:failure', $event)"
				@validation:success="log('validation:failure', $event)"
			)
		div
			v-switch(
				v-model="presentationStatusNotification"
				:label="$t('Project.notifications.allowEmailNotifications')"
			)
			p {{ $t('Project.notifications.descriptionAllowEmailNotifications') }}.
			v-switch(
				v-model="collectiveNotification"
				:label="$t('Project.notifications.collectiveMailToAdminsAndOperators')"
			)
			p {{ $t('Project.notifications.descriptionCollectiveMailToAdminsAndOperators') }}.
</template>

<script>
import GenericForm from '/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue';

export default
{
	name: 'Settings',
	components:
	{
		GenericForm,
	},
	created ()
	{

	},
	methods:
	{
		log: console.log,
	},
	data: (component) =>
	({
		activePanel: 0,

		allowUpload: false,
		presentationStatusNotification: false,
		collectiveNotification: false,

		timestampsFormat:
		[
			{
				name: 'start',
				required: true,
				type: 'datetime',
			},
			{
				name: 'end',
				required: true,
				type: 'datetime',
			},
		],
		timestampsAppearances:
		[
			{
				name: 'start',
				label: component.$t('Project.Dates.start.time'),
				grid:
				{
					sm: '6',
				},
			},
			{
				name: 'end',
				label: component.$t('Project.Dates.end.time'),
				grid:
				{
					sm: '6',
				},
			},
		],
		timestampsInitialData: {},
	}),
}
</script>