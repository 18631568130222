<template lang="pug">
v-form.form(ref="form")
	v-card.px-5.primary--text.rounded-xxl
		v-img.mx-auto.pb-12(contain, width="185", :src="logo")
		h2.text-center.mb-4 {{ $t('Project.common.loginTitle') }}
		v-text-field.pa-2(
			v-model="userEmail"
			rounded
			dense
			single-line
			hide-details
			style="border: 1.5px solid grey;"
			:label="$t('Project.common.mailAddress')"
			type="mail"
			color="secondary"
			prepend-icon="mdi-account"
			clearable
			:rules=`[
				$store.state.regexp.required,
				$store.state.regexp.mail,
			]`
			@keydown.enter="submit()"
		).mb-5
		v-text-field.pa-2(
			v-model="userPassword"
			:label="$t('Project.common.password')"
			color="secondary"
			prepend-icon="mdi-key"
			rounded
			dense
			single-line
			hide-details
			style="border: 1.5px solid grey;"
			:type="passwordVisible ? 'text' : 'password'"
			:append-outer-icon="passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
			@click:append-outer="passwordVisible = !passwordVisible"
			:rules=`[
				$store.state.regexp.required,
			]`,
			@keydown.enter="submit()"
		).mb-7
		v-card-actions
			//- router-link(to="/forgot") Passwort vergessen?
			v-spacer
			//- v-btn(depressed color="secondary", @click="submit()") {{ $t('common.login') }}
			v-chip.white--text.font-weight-bold.rounded-pill(color="secondary" @click="submit()") {{ $t('common.login') }}
</template>

<script>
export default
{
	name: 'Login',
	data: () => ({
		logo: require('@/assets/logo_colored.png'),
		passwordVisible: false,

		userEmail: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ADMIN_EMAIL : '',
		userPassword: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ADMIN_PASSWORD : '',
	}),
	methods: {
		submit ()
		{
			if (!this.$refs.form.validate()) return false;

			this.$store.dispatch('user/login', {
				data: {
					email: this.userEmail,
					password: this.userPassword,
				},
				request: {
					path: 'authenticate',
					options: {
					},
				},
			});
		},
	},
}
</script>