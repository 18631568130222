<template lang="pug">
v-container
	ObjectManagementList(
		:items="items"
		:formats="formats"
		:appearances="appearances"
		:initialDataCreateForm="initialDataCreateForm"
		:tableHeaders="tableHeaders"
		:dataTableOptions.sync="dataTableOptions"
		:loading.sync="loading"
		:maximumItemCount="maximumItemCount"
		:textAdd="{ header: $t('objects.room.create.title') }"
		:textUpdate="{ header: $t('objects.room.update.title') }"
		:textDelete="{ header: $t('objects.room.delete.title'), html: `<p>${$t('objects.room.delete.subtitle')}</p><p><b>${$t('objects.room.delete.question')}</b></p>` }"
		:actionColumnLabel="$t('common.actions')"
		@search="onSearch"
		@add-validation:success="submit(requests.create, {body: $event}); log($event);"
		@update-validation:success="submit(requests.update, {url: requests.delete.axios.url + '/' + $event._id, body: $event});"
		@delete:confirmation="submit(requests.delete, {url: requests.delete.axios.url + '/' + $event._id});"
		@update:dataTableOptions="log('update:dataTableOptions', $event); updateItems();"
		:showUpdate="Array.isArray(formats?.update) && formats.update.length > 0"
		:showDelete="Array.isArray(formats?.create) && formats.create.length > 0"
	)
		template(#header)
			div
				h1.myTitle {{ $tc('objects.room.title', 2) }}
				p.mb-0 {{ $t('objects.room.overview') }}
		template(#item.actions.extra="{ item }")
			v-btn(
				v-show="$store.getters['authorization/userCan']('read', urlBase, 'any') || ($store.getters['authorization/userCan']('read', urlBase, 'own') && $store.state.user?._events?.includes(item.event) || $store.state.user?._events?.length === 0)"
				class="mr-2"
				color="secondary"
				fab
				depressed
				x-small
				@click="download(item)"
			)
				v-icon mdi-download
</template>

<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';

export default {
	name: 'Rooms',
	components: {
		ObjectManagementList: require('@/components/ObjectManagementList.vue').default,
	},
	created ()
	{
		this.initialize();
	},
	computed:
	{
		requests ()
		{
			return {
				get:
				{
					store: 'items',
					axios:
					{
						url: this.urlBase,
						params:
						{
							event: this.$route.query.eventId,
						}
					}
				},
				create:
				{
					axios:
					{
						url: this.urlBase,
						method: 'post'
					}
				},
				update:
				{
					axios:
					{
						url: this.urlBase,
						method: 'put'
					}
				},
				delete:
				{
					axios:
					{
						url: this.urlBase,
						method: 'delete'
					}
				},
				initialize:
				[
					{
						store: 'formats.create',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'create',
								event: this.$route.query.eventId,
							}
						}
					},
					{
						store: 'formats.update',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'update',
								event: this.$route.query.eventId,
							}
						}
					},
				]
			}
		}
	},
	data: (component) => ({
		urlBase: 'rooms',
		items: [],
		formats: {},
		tableHeaders:
		[
			'title',
			'description',
		],
		appearances:
		[
			{
				name: 'title',
				label: component.$t('objects.room.properties.title')
			},
			{
				name: 'description',
				label: component.$t('objects.room.properties.description')
			},
			{
				name: 'sessions',
				label: component.$t('objects.room.properties.sessions')
			},
			{
				name: 'event',
				label: component.$t('objects.room.properties.event')
			}
		],

		initialDataCreateForm:
		{
			event: component.$route.query.eventId
		},

		loading: false,
		search: '',
		dataTableOptions: {},//{itemsPerPage: 5},
		maximumItemCount: 0,
	}),
	methods:
	{
		log: console.log,

		request: RequestHandler.request,

		initialize ()
		{
			RequestHandler.requests(this.requests.initialize, this)
			.catch(() => {});
		},

		submit (request, data)
		{
			const self = this;

			this.request(request, data)
			.then(function ()
			{
				self.updateItems();
			})
		},

		updateItems ()
		{
			// prevent the watcher from triggering itself
			if (this.loading)
			{
				return;
			}

			this.loading = true;



			RequestHandler.request(
				this.requests.get,
				null,
				this,
				Object.assign(this.dataTableOptions, { search: this.search })
			).then((response) =>
			{
				this.maximumItemCount = parseInt(response.headers.get('pagination-documents'));
				// we have to map session id's because generic form is not able to handle session objects
				this.items = this.items.map(i =>
				{
					i.sessions = i.sessions.map(s => s._id);

					return i;
				});

				setTimeout(() => {this.loading = false}, 0);
			});
		},
		
		onSearch (search)
		{
			if (this.loading)
			{
				return;
			}

			if (search !== this.search)
			{
				this.dataTableOptions.page = 1;
			}
			this.search = search;

			return this.updateItems();
		},

		download (_item)
		{
			const { _id } = _item;

			return window.open(
					this.$store.state.request.url + `${this.urlBase}/${_id}/export/?token=${this.$store.state.user.auth_token}`,
					'_blank'
			);
		},
	},
}
</script>