<template lang="pug">
v-container
	ObjectManagementList(
		:items="items"
		:formats="formats"
		:appearances="appearances"
		:initialDataCreateForm="initialDataCreateForm"
		:tableHeaders="tableHeaders"
		:dataTableOptions.sync="dataTableOptions"
		:loading.sync="loading"
		:maximumItemCount="maximumItemCount"
		:textAdd="{ header: $t('objects.participant.create.title') }"
		:textUpdate="{ header: $t('objects.participant.update.title') }"
		:textDelete="{ header: $t('objects.participant.delete.title'), html: `<p>${$t('objects.participant.delete.subtitle')}</p><p><b>${$t('objects.participant.delete.question')}</b></p>` }"
		:rules="$store.state.validation.rules"
		:ruleMessages="$store.state.validation.ruleMessages"
		:actionColumnLabel="$t('common.actions')"
		@search="onSearch"
		@add-validation:success="submit(requests.create, {body: $event}); log($event);"
		@update-validation:success="submit(requests.update, {url: requests.delete.axios.url + '/' + $event._id, body: $event});"
		@delete:confirmation="submit(requests.delete, {url: requests.delete.axios.url + '/' + $event._id});"
		@update:dataTableOptions="log('update:dataTableOptions', $event); updateItems();"
		:showUpdate="Array.isArray(formats?.update) && formats.update.length > 0"
		:showDelete="Array.isArray(formats?.create) && formats.create.length > 0"
	)
		template(#general-actions)
			v-btn.mr-2(
				v-if="Array.isArray(formats?.create) && formats.create.length > 0"
				fab
				depressed
				small
				color="secondary"
				@click="dialog = true"
			)
					v-icon mdi-upload
		template(#header)
			div
				h1.myTitle {{ $tc('Project.User.participants', 2) }}
				p.mb-0 {{ $tc('Project.User.descriptionParticipants') }}
		template(#item.role="item")
			span {{ translate(['user','role', item.role], item.role) }}

	ImportUsersForm(
		:show="dialog"
		:header="$t('objects.participant.import.title')"
		:subheader="$t('objects.participant.import.subtitle')"
		@import-users:success="dialog = false; updateItems()"
		@import-users:fail="dialog = false"
		@import-users:cancel="dialog = false"
	)
</template>

<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';
import ImportUsersForm from '@/components/ImportUsersForm.vue';
import Utility from '/node_modules/@gb-net/utility/utility.js';
import TranslationHelper from '@/customModules/libraries/translation.helper.js';
import FormHelper from '@/customModules/libraries/form.helper.js';

export default {
	name: 'Participants',
	components: {
		ObjectManagementList: require('@/components/ObjectManagementList.vue').default,
		ImportUsersForm
	},
	created ()
	{
		this.initialize();
	},
	computed:
	{
		requests ()
		{
			return {
				get:
				{
					store: 'items',
					axios:
					{
						url: this.urlBase,
						params:
						{
							event: this.$route.query.eventId,
						},
					},
				},
				create:
				{
					axios:
					{
						url: this.urlBase,
						method: 'post',
					},
				},
				update:
				{
					axios:
					{
						url: this.urlBase,
						method: 'put',
					},
				},
				delete:
				{
					axios:
					{
						url: this.urlBase,
						method: 'delete',
					},
				},
				initialize:
				[
					{
						store: 'formats.create',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'create',
								event: this.$route.query.eventId,
							},
						},
					},
					{
						store: 'formats.update',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'update',
								event: this.$route.query.eventId,
							},
						},
					},
				],
			}
		}
	},
	data: (component) => ({
		urlBase: 'users',
		items: [],
		formats: {create: [], update: []},
		tableHeaders:
		[
			'firstname',
			'lastname',
			'email',
			'role',
			'company',
		],

		dialog: false,

		appearances: [
			{
				name: 'firstname',
			},
			{
				name: 'lastname',
			},
			{
				name: 'email',
			},
			{
				name: 'company',
			},


			{
				name: 'phone',
				placeholder: 'z.B. +49 1234 5678',
			},
			{
				name: 'password',
			},
			{
				name: 'blocked',
			},
			{
				name: '_events',
			},
		].map(field => {if (!field.label) {field.label = component.$t(`objects.user.${field.name}`);} return field}),
		
		initialDataCreateForm: {
			role: 'speaker',
			_events: component.$route.query.eventId,
			blocked: false
		},
		
		loading: false,
		search: '',
		dataTableOptions: {},//{itemsPerPage: 5},
		maximumItemCount: 0,
		
		translations: TranslationHelper.translations,
	}),
	
	// temporary: values should come from server in the future instead
	watch:
	{
		'formats.create': function () {this.mergeFormat();},
		'formats.update': function () {this.mergeFormat();}
	},
	
	methods:
	{
		mergeFormat: function ()
		{
			const additionalValues =
			{
				firstname:
				{
					minimumLength: 2,
					maximumLength: 80
				},
				lastname:
				{
					minimumLength: 2,
					maximumLength: 80
				},
				password:
				{
					minimumLength: 2,
					maximumLength: 80
				},
				company:
				{
					minimumLength: 2,
					maximumLength: 80
				},
				phone:
				{
					minimumLength: 3,
					maximumLength: 24
				}
			};
			
			for (const field of this.formats.create)
			{
				if (additionalValues[field.name])
				{
					for (const [key, value] of Object.entries(additionalValues[field.name]))
					{
						field[key] = value;
					}
				}
			}
		},
		
		log: console.log,

		request: RequestHandler.request,

		initialize ()
		{
			const self = this;

			RequestHandler.requests(this.requests.initialize, this)
			.then(() =>
			{
				this.appearances.push(
					{
						name: 'role',
						label: this.$t('pages.Participants.role'),
						labels: Object.values(this.formats.create.filter(field => field.name === 'role')?.[0].values).map(value => this.$t(`pages.Participants.roles.${value}`))
					},
				);
			}).catch(() => {});
		},

		submit (request, data)
		{
			const self = this;

			this.request(request, data)
			.then(function ()
			{
				self.updateItems();
			})
		},
		updateItems ()
		{
			// prevent the watcher from triggering itself
			if (this.loading)
			{
				return;
			}

			this.loading = true;

			RequestHandler.request(
				this.requests.get,
				null,
				this,
				Object.assign(this.dataTableOptions, { search: this.search })
			).then((response) =>
			{
				this.maximumItemCount = parseInt(response.headers.get('pagination-documents'));
				this.formats.create = FormHelper.restrictFormatsToEvent(this.formats.create, this.$route.query.eventId);
				this.formats.update = FormHelper.restrictFormatsToEvent(this.formats.update, this.$route.query.eventId);

				setTimeout(() => {this.loading = false}, 0);
			});
		},
		
		onSearch (search)
		{
			if (this.loading)
			{
				return;
			}

			if (search !== this.search)
			{
				this.dataTableOptions.page = 1;
			}
			this.search = search;

			return this.updateItems();
		},

		translate (path, alternative)
		{
			const value = Utility.object.resolveValue(this.translations, path);

			return value ?? alternative;
		}
	},
}
</script>