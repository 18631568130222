<template>
	<div class="customCard">
		<div
			class="d-flex"
		>
			<slot
				name="header"
			/>
			<v-spacer/>
			<slot
				name="general-actions"
			/>
			<v-btn
				v-show="showAdd && Array.isArray(formats?.create) && formats.create.length > 0"
				class="ml-2"
				fab
				small
				depressed
				color="success"
				@click="dialogs.add = true"
			>
				<v-icon dark>mdi-plus</v-icon>
			</v-btn>
		</div>
		<div
			v-show="showSearch"
			class="mt-5 pb-4"
		>
			<v-text-field
				v-model="search"
				class="pr-2"
				:label="$t('common.search')"
				style="width: 38%; border: 1.5px solid gray;"
				append-outer-icon="mdi-magnify"
				single-line
				dense
				rounded
				hide-details
				@input="searchHandler"
			/>
		</div>
		<v-card class="customCard transparent" elevation="0">
			<!-- USED FOR DEBUGGING PURPOSES -->
			<!-- <div
				v-for="header of headers"
				:key="`item.${header.value}`"
			>
				item.{{ header.value }}
			</div>
			<div>
				{{ (tableHeaders ? appearances : appearances.filter(a => tableHeaders.includes(a.name))) }}
			</div>
			<div>
				{{ items[0] }}
			</div> -->
			<v-data-table
				class="transparent"
				:headers="headers"
				:items="items"
				:options="dataTableOptions"
				@update:options="$emit('update:dataTableOptions', $event)"
				:loading="loading"
				:server-items-length="maximumItemCount"
				:items-per-page="12"
				:footer-props="{'items-per-page-options': [5, 10, 12, 15, -1], itemsPerPageText: $t('Project.table.rowsPerPage')}"
			><!-- :options.sync="dataTableOptions" -->
				<template
					v-for="header of (tableHeaders ? appearances : appearances.filter(a => tableHeaders.includes(a.name)))"
					:slot="`item.${header.name}`"
					slot-scope="{ item }"
				>
					<slot
						:name="`item.${header.name}`"
						v-bind="item"
					>
						{{ item[header.name] ?? '' }}
					</slot>
				</template>
				<template #item.actions="{ item }">
					<div class="d-flex justify-space-between">
						<slot
							name="item.actions.extra"
							:item="item"
						/>
						<v-btn
							v-show="showUpdate"
							class="mr-2"
							color="secondary"
							fab
							x-small
							depressed
							@click="selectedItem = item; dialogs.update = true"
						>
							<v-icon>
								mdi-pencil
							</v-icon>
						</v-btn>
						<v-btn
							v-show="showDelete"
							color="delete"
							fab
							x-small
							depressed
							@click="selectedItem = item; dialogs.delete = true"
						>
							<v-icon class="white--text">
								mdi-delete
							</v-icon>
						</v-btn>
					</div>
				</template>
				<template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} {{ $t('common.of') }} {{ items.itemsLength }} </template>
			</v-data-table>

			<v-dialog
				v-model="dialogs.add"
				max-width="700"
				eager
			>
				<v-card class="customCard">
					<v-card-title
						class="pb-4"
					>
						<h2>{{ textAdd?.header }}</h2>
						<p>{{ textAdd?.text }}</p>
					</v-card-title>

					<v-card-text
						class="pb-0"
					>
						<GenericForm
							ref="form-add"
							horizontalLayoutGap="24px"
							verticalLayoutGap="0px"
							compensateContainerGapHorizontally
							:format="formats?.create"
							:appearances="appearances"
							:initialData="initialDataCreateForm"
							:rules="rules"
							:ruleMessages="ruleMessages"
							@validation:failure="$emit('add-validation:failure', $event); log($event)"
							@validation:success="$emit('add-validation:success', $event); dialogs.add = false; $refs['form-add'].reset()"
						/>
					</v-card-text>

					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="cancel"
							class="white--text"
							@click="dialogs.add = false; $refs['form-add'].resetValidation()"
						>
							{{ $t('common.cancel') }}
						</v-btn>
						<v-btn
							color="save"
							class="white--text"
							@click="$refs['form-add'].validate()"
						>
							{{ $t('common.save') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="dialogs.update"
				max-width="700"
				eager
			>
				<v-card class="customCard">
					<v-card-title
						class="pb-4"
					>
						<h2>{{ textUpdate?.header }}</h2>
						<p>{{ textUpdate?.text }}</p>
					</v-card-title>

					<v-card-text
						class="pb-0"
					>
						<GenericForm
							ref="form-update"
							horizontalLayoutGap="24px"
							verticalLayoutGap="0px"
							compensateContainerGapHorizontally
							:format="formats?.update"
							:appearances="appearances"
							:initialData="selectedItem"
							:rules="rules"
							:ruleMessages="ruleMessages"
							@validation:failure="$emit('update-validation:failure', $event)"
							@validation:success="$emit('update-validation:success', Object.assign({}, selectedItem, $event)); dialogs.update = false"
						/>
					</v-card-text>

					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="cancel"
							class="white--text"
							@click="dialogs.update = false; selectedItem = null; $refs['form-update'].resetValidation()"
						>
							{{ $t('common.cancel') }}
						</v-btn>
						<v-btn
							color="save"
							class="white--text"
							@click="$refs['form-update'].validate()"
						>
							{{ $t('common.update') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="dialogs.delete"
				max-width="700"
			>
				<v-card class="customCard">
					<v-card-title>
						<h2>{{ textDelete?.header }}</h2>
					</v-card-title>

					<v-card-text
						v-if="textDelete?.html.length"
						:v-html="textDelete.html"
					>
						<span v-html="textDelete.html"></span>
					</v-card-text>
					<v-card-text
						v-else
					>
						{{ textDelete?.text }}
					</v-card-text>

					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="cancel"
							class="white--text"
							@click="dialogs.delete = false; selectedItem = null"
						>
							{{ $t('common.cancel') }}
						</v-btn>
						<v-btn
							color="delete"
							class="white--text"
							@click="$emit('delete:confirmation', selectedItem); dialogs.delete = false"
						>
							{{ $t('common.confirmDeletion') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card>
	</div>
</template>

<script>
export default
{
	name: 'ObjectManagementList',

	components:
	{
		GenericForm: require('/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue').default,
	},

	props:
	{
		tableHeaders:
		{
			type: Array,
			required: false,
			default: () => ([])
		},

		items:
		{
			type: Array,
			required: false,
			default: () => ([])
		},

		maximumItemCount:
		{
			type: Number,
			required: false,
			default: -1
		},

		loading:
		{
			type: Boolean,
			required: false,
			default: false
		},

		dataTableOptions:
		{
			type: Object,
			required: false,
			default: () => ({})
		},

		initialDataCreateForm:
		{
			type: Object,
			required: false,
			default: () => ({})
		},

		formats:
		{
			type: Object,
			required: false,
			default: () => ({create: [], update: []})
		},

		appearances:
		{
			type: Array,
			required: false,
			default: () => ([])
		},

		showSearch:
		{
			type: Boolean,
			required: false,
			default: true
		},

		showAdd:
		{
			type: Boolean,
			required: false,
			default: true
		},

		textAdd:
		{
			type: Object,
			required: false,
			default: () => ({header: 'Add new object', text: ''})
		},

		showUpdate:
		{
			type: Boolean,
			required: false,
			default: true
		},

		textUpdate:
		{
			type: Object,
			required: false,
			default: () => ({header: 'Update existing object', text: ''})
		},

		showDelete:
		{
			type: Boolean,
			required: false,
			default: true
		},

		textDelete:
		{
			type: Object,
			required: false,
			default: () => ({header: 'Delete object', text: 'Are you sure you want to permanently delete the selected object?', html: ''})
		},
		
		actionColumnLabel:
		{
			type: String,
			required: false,
			default: 'Aktionen'
		},
		
		// additional form rules
		rules:
		{
			type: Object,
			required: false,
			default: () => ({})
		},
		
		// additional form messages
		ruleMessages:
		{
			type: Object,
			required: false,
			default: () => ({})
		},
	},

	mounted ()
	{
		// REF WORKS ONLY WHEN: DIALOG WAS OPEN AT LEAST ONCE / DIALOG HAS EAGER PROP!
		this.$refs['form-add'];
	},

	methods:
	{
		log: console.log,
		
		setTimeout: setTimeout,
		
		searchHandler: Utility.function.debounce(
			function ()
			{
				this.$emit('search', this.search);
			},
			500
		)
	},

	computed:
	{
		headers ()
		{
			const headers = [];

			this.appearances.forEach((appearance) =>
			{
				if (this.tableHeaders.length > 0 && !this.tableHeaders.includes(appearance.name))
				{
					return;
				}

				headers.push(
					{
						value: appearance.name,
						text: appearance.label
					}
				);
			});

			// if (!this.showUpdate && !this.showDelete && !this.$scopedSlots['item-actions'])
			// {
			// 	return headers;
			// }

			return [...headers, this.actionHeader];
		}
	},

	data: (component) =>
	(
		{
			search: '',
			actionHeader:
			{
				text: component.actionColumnLabel,
				value: 'actions',
				// does not actually make the column 1px in width, makes it instead grow with content
				width: '1px',
				sortable: false
			},
			selectedItem: null,
			dialogs:
			{
				add: false,
				update: false,
				delete: false,
			}
		}
	)
};
</script>

<style lang="scss" scoped>

</style>