import { render, staticRenderFns } from "./OverviewGrid.vue?vue&type=template&id=83c13166&scoped=true&lang=pug"
import script from "./OverviewGrid.vue?vue&type=script&lang=js"
export * from "./OverviewGrid.vue?vue&type=script&lang=js"
import style0 from "./OverviewGrid.vue?vue&type=style&index=0&id=83c13166&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83c13166",
  null
  
)

export default component.exports