import Configuration from '@/customModules/libraries/configuration.js';

// TODO AutoImport possible?
const self =
{
	axios: require('@/customModules/libraries/axios.library.js'),
}

export default
{
	namespaced: true,
	state:
	{
		requestLibrary: 'axios',
		url: Configuration.getEnvironmentalValue('url'),
	},
	getters:
	{
	},
	mutations:
	{
	},
	actions:
	{
		buildHeaders ({ state, rootState }, payload)
		{
			addStandardHeaders();
			addAdditionalHeaders();
		},

		prepareRequest () { },

		// build(){
		//	 this.buildQueryString();
		//	 this.buildHeaders();
		// },

		async navigateToModule ({ state, rootState }, payload)
		{
			try
			{
				const { auth_token } = rootState?.user;

				const additionalHeaders = {};
				if (auth_token) additionalHeaders['x-access-token'] = auth_token;

				if (!payload.request?.options) payload.request.options = {};
				if (!payload.request?.options?.headers) payload.request.options.headers = {};


				Object.assign(payload.request.options.headers, additionalHeaders);


				const myParams = payload?.request?.params;
				if (myParams)
				{
					let queryString = '';

					for (const key in myParams)
					{
						if (myParams.hasOwnProperty(key)) queryString += `${key}=${myParams[key]}&`;
					}

					if (queryString.length) payload.request.path += '?' + queryString;
				}


				let { library } = payload.request;
				if (!library) library = state.requestLibrary;


				return await self[library].default['init'](payload);
			} catch (err)
			{
				throw err;
			}
		},
		get ({ dispatch }, payload)
		{
			payload.request.method = 'GET';

			return dispatch('navigateToModule', payload);
		},
		post ({ dispatch }, payload)
		{
			payload.request.method = 'POST';

			return dispatch('navigateToModule', payload);
		},
		put ({ dispatch }, payload)
		{
			payload.request.method = 'PUT';

			return dispatch('navigateToModule', payload);
		},
		delete ({ dispatch }, payload)
		{
			payload.request.method = 'DELETE';

			return dispatch('navigateToModule', payload);
		},
	},
};