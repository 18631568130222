export default {
	namespaced: true,
	state: {
		website: 'https://www.gb-mediensysteme.de/',
		firm: {
			long: 'Gahrens + Battermann GmbH & Co. KG',
			short: 'G+B',
			interactive: 'G+B Interactive',
		},
		items: [
			{
				title: 'policy',
				link: 'https://www.gb-mediensysteme.de/datenschutzerklaerung/',
			},
			{
				title: 'imprint',
				link: 'https://www.gb-mediensysteme.de/impressum/',
			},
		],
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
};
