import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify(
	{
		icons:
		{
			iconfont: 'mdi'
		},
		theme:
		{
			options:
			{
				// setting this option exposes the color variables so that we can use them
				// in style blocks like this:
				// color: var(--v-primary-base);
				// where 'primary' takes the color value defined as 'primary' and 'base'
				// refers to the currently selected theme
				// there are generated variations based on the theme colors, inspect html
				// element to see all available variables
				customProperties: true,
				cspNonce: 'KOkp39JTViTcTymkuAzeiLCu',
			},
			themes:
			{
				light:
				{
					primary: '#000000',
					secondary: '#872046',
					tertiary: '#FF5702',
					success: '#008083',
					warning: '#C8005D',
					error: '#C8005D',
					info: '#002FA2',
					link: '#002FA2',

					dark: '#000000',
					light: '#ffffff',
					//background: '#ffffff',

					/* dialog buttons */
					save: '#008083',
					cancel: '#cccccc',
					delete: '#C8005D',

					/* file status */
					uploaded: '#ccbf08',
					missing: '#000000',
					approved: '#008083',
					review: '#002FA2',
					rejected: '#C8005D',
				},
			},
		},
	},
);