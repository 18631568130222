<template lang="pug">
div
	v-row
		v-col.d-flex.error.white--text.mt-8.mb-13.py-5(
			cols="10"
			v-if="!items?.length && show"
		)
			v-icon.ml-7.mr-10(color="white", x-large) mdi-exclamation-thick
			div
				.title.mb-4
					h1 {{ $t('pages.OverviewGrid.tutorial.title') }}
				.subtitle
					p {{ $t('pages.OverviewGrid.tutorial.subtitle1') }}
					p {{ $t('pages.OverviewGrid.tutorial.subtitle2') }}
					p
						i {{ $t('pages.OverviewGrid.tutorial.additional') }}

	v-row.mt-6
		v-col(
			v-for="(item, i) in items",
			:key="i",
			cols="6"
		)
			v-card.d-flex.flex-column(min-height="400")
				div.d-flex.align-center.white--text.py-2.px-5(
					:class="item?.status ?? black"
				)
					h3.fileType {{ replaceFileTypeAbbreviationWithOriginalWord(item.type) }}
					v-spacer
					p.my-3 {{ $t(`pages.Session.fileStatus.${item.status}`) }}
				v-card-text.d-flex
					div(v-if="item?.name")
						p.mb-0 {{ $t('pages.SessionFiles.filename') }}:
						b {{ item.name }}
						div(v-if="item?.owner?.displayname")
							p.mb-0 {{ $t('pages.SessionFiles.owner') }}:
							b {{ item.owner.displayname }}
					v-spacer
					div
						p.mb-0 {{ $t('pages.SessionFiles.createdAt') }}:
						b {{ formatInternationalDate(item.createdAt) }}
						p.mb-0 {{ $t('pages.SessionFiles.updatedAt') }}:
						b {{ formatInternationalDate(item.updatedAt) }}
				v-spacer.d-flex.justify-center.align-center
					v-btn(
						v-show="item.status === 'missing'"
						x-large
						color="secondary"
						@click="$emit('toggleFileUpload', item)"
					) Upload missing file
				v-card-actions
					div(v-if="isAllowedForStatusChange()")
						v-btn.mr-2(
							color="success"
							fab
							x-small
							depressed
							@click="$emit('quickSetFilestatus', item, 'approved')")
								v-icon mdi-check
						v-btn.mr-2(
							color="error"
							fab
							x-small
							depressed
							@click="$emit('quickSetFilestatus', item, 'rejected')")
								v-icon mdi-close
					v-spacer
					div(v-if="tableActions")
						v-btn.ml-2(
							:disabled="item.status === 'approved'"
							color="secondary"
							fab
							x-small
							depressed
							@click="$emit('toggleUpdate', item)"
						)
							v-icon mdi-square-edit-outline

						v-btn.ml-2(
							v-if="item.status !== 'missing'"
							color="secondary"
							fab
							x-small
							depressed
							@click="$emit('toggleExport', item)"
						)
							v-icon mdi-download

						v-btn.ml-2(
							v-if="item.status === 'missing'"
							color="secondary"
							fab
							x-small
							depressed
							@click="$emit('toggleFileUpload', item)"
						)
							v-icon mdi-upload

						v-btn.ml-2(
							:disabled="item.status === 'approved'"
							color="red"
							fab
							depressed
							x-small
							@click="$emit('toggleDelete', item)"
						)
							v-icon.white--text mdi-delete
</template>

<script>
import Utility from '/node_modules/@gb-net/utility/utility.js';


export default {
	name: 'OverviewGrid',
	props: [
		'useSearch',
		'tableActions',
		'tableItems',
		'items',
		'show'
	],
	data: (component) => ({
	}),
	methods:
	{
		isAllowedForStatusChange: function () 
		{
			const rolesForAdminView = ['admin', 'event-admin', 'operator'];

			return rolesForAdminView.includes(this.$store.state.user?.role);
		},
		canDownload (fileItem)
		{
			// there is nothing we can check
			if (typeof fileItem?.status !== 'string') return true;

			return fileItem.status !== 'missing';
		},
		canUpload (fileItem)
		{
			// there is nothing we can check
			if (typeof fileItem?.status !== 'string') return true;

			return fileItem.status === 'missing' || fileItem.status === 'rejected';
		},
		formatInternationalDate (isoDateString)
		{
			const options = {
				day: '2-digit',
				year: 'numeric',
				month: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false,
			};

			return new Date(isoDateString).toLocaleDateString(undefined, options);
		},
		replaceFileTypeAbbreviationWithOriginalWord (_abbreviation)
		{
			let abbreviationMap = {
				'ppt': 'presentation',
				'pdf': 'pdf',
				'video': 'video',
				'font': 'font',
			};

			let originalWord = abbreviationMap[_abbreviation]

			return this.$t(`Project.common.fileFormats.${originalWord}`);
		},
	},
}
</script>

<style lang="sass" scoped>
	.cardBody
		.v-card__text
			color: gray
			p
				margin-bottom: 0
</style>