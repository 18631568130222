import TranslationHelper from '@/customModules/libraries/translation.helper.js';

export default
{
	namespaced: true,
	state:
	{
		statusColors:
		{
			uploaded: {
				label: TranslationHelper.translations.file.status.uploaded,
				color: 'uploaded',
			},
			review: {
				label: TranslationHelper.translations.file.status.review,
				color: 'review',
			},
			approved: {
				label: TranslationHelper.translations.file.status.approved,
				color: 'approved',
			},
			missing: {
				label: TranslationHelper.translations.file.status.missing,
				color: 'missing',
			},
			rejected: {
				label: TranslationHelper.translations.file.status.rejected,
				color: 'rejected',
			},
		},
	},
}