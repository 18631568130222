<template lang="pug">
div
	Administrator(
		v-if="isAllowedForAdminView()",
	)
	Speaker(
		v-else-if="$store.state.user?.role === 'speaker'"
	)
	//- TODO: Views are supposed to be assignes to any Role so that it automatically adjusts, depending on the Filestructure inside @/views
	//- AssignViewsToRole()
</template>

<script>
import AutoImport from '@/customModules/AutoImport.js';
import DateHandler from '@/customModules/utils/DateHandler.js';


export default
{
	name: 'Home',
	components: AutoImport.filesFromDirectory('views/Home', ['Administrator', 'Speaker']),
	data: (component) =>
	({
		DateHandler,
	}),
	methods:
	{
		isAllowedForAdminView: function () 
		{
			const rolesForAdminView = ['admin', 'event-admin', 'operator'];

			return rolesForAdminView.includes(this.$store.state.user?.role);
		}
	},
}
</script>