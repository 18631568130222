export default (function()
{
	const options =
	{
		weekday: 'long',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		hour12: false,
		minute: '2-digit',
	};
	
	const convertUTCDateToLocalDate = (_UTCDate) =>
	{
		const convertedDate = new Date(_UTCDate.toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
		return convertedDate.toLocaleDateString('de-DE', options);
	}
	
	return {
		convertUTCDateToLocalDate,
	}
})();