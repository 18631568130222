<template lang="pug">
v-container
	ObjectManagementList(
		:items="items"
		:formats="formats ?? {}"
		:appearances="appearances"
		:tableHeaders="tableHeaders"
		:options.sync="dataTableOptions"
		:loading.sync="loading"
		:textAdd="{ header: $t('objects.presentation.create.title') }"
		:textUpdate="{ header: $t('objects.presentation.update.title') }"
		:textDelete="{ header: $t('objects.presentation.delete.title'), html: `<p>${$t('objects.room.delete.subtitle')}</p><p><b>${$t('objects.room.delete.question')}</b></p>` }"
		@add-validation:failure="log('ADD VALIDATION FAILED!', $event)"
		@add-validation:success="submit(requests.create, {body: $event}); log($event);"
		@update-validation:failure="log('UPDATE VALIDATION FAILED!', $event)"
		@update-validation:success="submit(requests.update, {url: requests.delete.axios.url + '/' + $event._id, body: $event});"
		@delete:confirmation="submit(requests.delete, {url: requests.delete.axios.url + '/' + $event._id});"
		@update:dataTableOptions="log('update:dataTableOptions', $event); updateItems();"
	)
		template(#header)
			div
				h1.myTitle {{ $tc('objects.room.title', 2) }}
				p.mb-0 {{ $t('objects.presentation.create.subtitle') }}
		template(#item.status="item")
			v-chip(
				:color="$store.state.presentations.statusColors[item.status].color"
				:dark="['missing', 'rejected'].includes(item.status)"
			) {{ item.status }}

		template(#item.actions.extra="{ item }")
			v-btn.mr-2(
				v-show="$store.getters['authorization/userCan']('update', 'presentations')"
				color="secondary"
				fab
				x-small
				depressed
				@click="log('UPLOAD CLICKED')"
			)
				v-icon mdi-upload

		template(#item.actions.extra="{ item }")
			v-btn.mr-2(
				v-show="$store.getters['authorization/userCan']('read', urlBase)"
				color="secondary"
				fab
				x-small
				depressed
				@click="$router.push({ path: '/event', query: { eventId: item._id } })"
			)
				v-icon mdi-upload
</template>

<script>
import RequestHandler from '@/customModules/libraries/requesthandler.js';

export default
{
	name: 'Presentations',
	components:
	{
		ObjectManagementList: require('@/components/ObjectManagementList.vue').default,
	},
	created ()
	{
		RequestHandler.requests(this.requests.initialize, this);
	},
	created ()
	{
		this.initialize();
	},
	computed:
	{
		requests ()
		{
			return {
				get:
				{
					store: 'items',
					axios:
					{
						url: this.urlBase
					}
				},
				create:
				{
					axios:
					{
						url: this.urlBase,
						method: 'post'
					}
				},
				update:
				{
					axios:
					{
						url: this.urlBase,
						method: 'put'
					}
				},
				delete:
				{
					axios:
					{
						url: this.urlBase,
						method: 'delete'
					}
				},
				initialize:
				[
					{
						store: 'formats.create',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'create'
							}
						}
					},
					{
						store: 'formats.update',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'update'
							}
						}
					},
				]
			}
		}
	},
	data: (component) =>
	({
		urlBase: 'files',
		items: [],
		formats: {},
		tableHeaders:
		[
			'name',
			'status',
		],
		appearances:
		[
			{
				name: 'name',
				label: 'Dateiname'
			},
			{
				name: 'type',
				label: 'Dateityp',
			},
			{
				name: 'status',
				label: 'Status',
			},
			{
				name: 'owner',
				label: 'Besitzer',
			},
			{
				name: 'session',
				label: 'Session auswählen',
			},
			{
				name: 'event',
				label: 'Veranstaltung auswählen',
			},
		],


		initialDataCreateForm: {},



		loading: false,
		dataTableOptions:
		{
			// page: 1,
			itemsPerPage: 5,
		},
		maximumItemCount: 0,
	}),
	methods:
	{
		log: console.log,

		request: RequestHandler.request,

		initialize ()
		{
			RequestHandler.requests(this.requests.initialize, this);
		},

		submit (request, data)
		{
			const self = this;

			this.request(request, data)
			.then(function ()
			{
				self.updateItems();
			})
		},
		updateItems ()
		{
			// prevent the watcher from triggering itself
			if (this.loading)
			{
				return;
			}

			this.loading = true;

			RequestHandler.request(
				this.requests.get,
				null,
				this,
				this.dataTableOptions
			).then((response) =>
			{
				this.maximumItemCount = parseInt(response.headers.get('pagination-documents'));

				setTimeout(() => {this.loading = false}, 0);
			});
		}
	},
}
</script>