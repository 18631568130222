<template lang="pug">
div
	v-text-field.mb-4(
		v-if="useSearch",
		v-model="search",
		:label="labelSearch",
		append-icon="mdi-magnify",
		single-line,
		hide-details
	)
	v-data-table.elevation-2(
		:loading="loading",
		:search="search",
		:append-icon="searchAppendIcon",
		:label="labelSearch",
		:headers="tableHeaders",
		:items="tableItems",
		:items-per-page="itemsperpage"
	)
		template(v-slot:item.actions="{ item }")
			.actions.d-flex.align-center.justify-end(
				v-show="!$store.getters['authorization/userCan']('update', items.path)"
			)
				v-btn(
					v-show="$store.getters['authorization/userCan']('read', 'events', ) && tableActions?.selectEvent",
					color="grey",
					fab,
					x-small,
					@click="$emit('selectEvent', item)"
				)
					v-icon.white--text mdi-dashboard

				v-btn(
					v-show="$store.getters['authorization/userCan']('update', items.path, 'own') && tableActions?.delete",
					color="secondary",
					fab,
					x-small,
					@click="$emit('toggleUpdate', item)"
				)
					v-icon.white--text mdi-square-edit-outline

				v-btn(
					v-show="$store.getters['authorization/userCan']('delete', items.path) && tableActions?.export",
					color="secondary",
					fab,
					x-small,
					@click="$emit('toggleExport', item)"
				)
					v-icon.white--text mdi-download

				v-btn(
					v-show="$store.getters['authorization/userCan']('delete', items.path) && tableActions?.delete"
					color="red"
					fab
					depressed
					x-small
					@click="$emit('toggleDelete', item)"
				)
					v-icon.white--text mdi-delete
		template(#item.start="{ item }") {{ new Date(item.start).toLocaleString($i18n.locale) }}
		template(#item.end="{ item }") {{ new Date(item.end).toLocaleString($i18n.locale) }}
</template>

<script>
export default
{
	name: 'OverviewList',
	props:
	[
		'useSearch',
		'tableHeaders',
		'items',
		'tableActions',
		'tableItems',
		'itemsperpage',
		'dataRequestFinished',
	],
	methods:
	{
		log: console.log
	},
	computed:
	{
		loading ()
		{
			return !this.dataRequestFinished && !this.tableItems.length;
		},
	},
	data: (component) =>
	(
		{
			labelSearch: component.$t('common.search'),
			search: '',
			searchAppendIcon: component.search ? 'mdi-magnify' : '',
		}
	)
}
</script>

<style lang="sass">
.actions
	column-gap: 10px
</style>
