/**
 * @file Module for managing requests and triggering multiple automatically
 * @author Josua Todebusch
 * @version 1.0.0
 */

// axios single template
/* axios(
	{
		method: 'get',
		url: this.$store.state.request.url + 'events/formfields',
		headers:
		{
			'x-access-token': this.$store.state.user.auth_token
		},
		params:
		{
			action: 'create'
		}
	}
)
.then(response => {this.log(response);this.format = response.data})
.catch(error => {this.log(error);this.$store.commit('snackbar/show', { msg: error.message, status: 'error' })}); */

import axios from 'axios';
import Store from '@/store';
import configuration from '@/configuration.json';
import Utility from '/node_modules/@gb-net/utility/utility.js';

const RequestHandler = (function ()
{
	const log =
	{
		requests: configuration.logRequests,
		responses: configuration.logRequests,
		errors: true
	};
	
	const requestBase = () =>
	{
		return {
			method: 'get',
			headers:
			{
				'x-access-token': Store.state.user.auth_token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		};
	}
	
	return {
		requests: function (requests, store)
		{
			return Promise.all(
				requests.map(
					request => this.request(request, null, store)
				)
			)
		},
		
		request: function (request, additionalData, store, paginationOptions)
		{
			const processed = Utility.object.merge(
				requestBase(),
				Utility.object.merge(
					request.axios,
					additionalData ?? {}
				)
			);
			
			processed.url = Store.state.request.url + processed.url;
			
			if (paginationOptions)
			{
				const options =
				{
					page: paginationOptions.page,
					perPage: paginationOptions.itemsPerPage < 0 ? 10000 : paginationOptions.itemsPerPage,
				};
				
				if (paginationOptions.search?.length)
				{
					options.search = paginationOptions.search;
				}

				if (paginationOptions.sortBy?.length)
				{
					options.sort = `${paginationOptions.sortDesc?.length && paginationOptions.sortDesc[0] ? '-' : ''}${paginationOptions.sortBy[0]}`;
				}
				
				processed.url += '?' + Utility.object.toUrlParameterString(options);
			}
			
			if (log.requests)
			{
				console.log('RequestHandler request', processed);
			}
			
			return new Promise(async function (resolve, reject)
			{
				const { method, url, body, ...options } = processed;
				let axiosRequest;

				if (typeof options === 'object')
				{
					options.onUploadProgress = event => {
						const percentCompleted = Math.round((event.loaded * 100) / event.total);
						
						console.log('onUploadProgress', percentCompleted);
					};	
				}

				// workaround: body is lost with axios(processed) on put and post requests...?!
				if (method === 'put' || method === 'post')
				{
					axiosRequest = axios[method](url, body, options);
				}
				else
				{
					axiosRequest = axios[method](url, options);
				}

				axiosRequest.then(
					response =>
					{
						if (log.responses)
						{
							console.log('RequestHandler response', url, response);
						}
						
						if (!store)
						{
							return resolve(response);
						}
						
						Utility.object.integrateValue(store, request.store.split('.'), response.data);
						
						resolve(response);
					}
				)
				.catch(
					error =>
					{
						if (log.errors)
						{
							console.error('RequestHandler error', error, url, processed);
						}
						if (error?.status !== 403)
						{
							Store.commit('snackbar/show', { msg: error.message, status: 'error' });
						}
						
						reject(error);
					}
				);
			});
		}
	}
})();

export default RequestHandler;