export default
{
	namespaced: true,
	state:
	{
		msg: '',
		status: false,
		timeout: 4000,
	},
	getters:
	{

	},
	mutations:
	{
		show (state, payload)
		{
			Object.assign(state, payload);
		}
	},
	actions:
	{

	},
};

