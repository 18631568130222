import axios from 'axios';
import Configuration from '@/customModules/libraries/configuration.js';

export default (function ()
{
	const instanceOptions = {
		baseURL: Configuration.getEnvironmentalValue('url')
	}

	const instance = axios.create(instanceOptions);


	const init = function (sendable)
	{
		const { request } = sendable;

		return this[request.method](sendable);
	}

	const GET = (payload) =>
	{
		try
		{
			const { request } = payload;
			const { path, options } = request;

			return instance.get(path, options)
				.then(res =>
				{
					const data = res?.data;
					if (!data) return false;

					return data;
				})
				.catch(err => {
					throw err;
				})
		} catch (err)
		{
			throw err;
		}
	}

	const POST = (payload) =>
	{
		try
		{
			const { data, request } = payload;
			const { path, options } = request;

			return instance.post(path, data, options)
				.then(res =>
				{
					if (!res?.data) reject(false);

					return res.data;
				}).catch(err =>
				{
					throw err;
				});
		} catch (err)
		{
			throw err;
		}
	}

	const PUT = (payload) =>
	{
		try
		{
			const { data, request } = payload;
			const { path, options } = request;

			return instance.put(path, data, options)
				.then(res =>
				{
					if (!res?.data) reject(false);

					return res.data;
				})
				.catch(err =>
				{
					throw err;
				});
		} catch (err)
		{
			throw err;
		}
	}


	const DELETE = (payload) =>
	{
		try
		{
			const { request } = payload;
			const { path, options } = request;

			return instance.delete(path, options)
				.then(res =>
				{
					if (!res?.data) reject(false);

					return res.data;
				}
				);
		} catch (err)
		{
			throw err;
		}
	}

	return {
		init,
		GET,
		POST,
		PUT,
		DELETE,
	}
})();
