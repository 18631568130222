import i18n from '@/plugins/i18n.js'

import router from '@/router';

import store from '../index';

import configuration from '@/configuration.json';

export default
{
	namespaced: true,
	state:
	{
		auth_token: localStorage.getItem('auth_token'),
		_id: '',
		firstname: '',
		lastname: '',
		email: '',
		role: '',
		company: '',
		phone: '',
	},
	getters:
	{
		getName (state)
		{
			return `${state?.firstname} ${state?.lastname}`;
		},
	},
	mutations:
	{
		logout ()
		{
			localStorage.removeItem('auth_token');
			Object.assign(store.state, {
				items: [],
				status: 'empty',
			});

			store.commit('snackbar/show', {
				msg: i18n.t('common.successfullyLoggedOut'),
				status: 'success',
			});

			router.push('/user/login');
		},
		welcomeUser ()
		{
			setTimeout(() => {
				store.commit('snackbar/show', {
					msg: i18n.tc('common.welcome', 2, { name: store.state.user.firstname }),
					status: 'success',
				}, { root: true });
			}, 500);
		},
		redirectToHomePage ()
		{
			router.push('/');
		},
	},
	actions:
	{
		getMe ({ state, commit, dispatch })
		{
			return dispatch(
				'request/get',
				{
					request:
					{
						path: 'users/me',
					},
				},
				{
					root: true,
				},
			).then(res =>
			{
				if(res?.success === false) commit('logout');

				Object.assign(state, res);
				return res;
			}).catch(err =>
			{
				store.commit('snackbar/show', { msg: err.message });
				throw Error(err);
			});
		},

		getGrants ({ rootState, dispatch, rootGetters })
		{
			dispatch(
				'request/get',
				{
					request: {
						path: 'grants',
					},
				},
				{
					root: true,
				},
			)
				.then(res => {
					const accesses = store.state.authorization.access = res;

					return accesses
				}
			)
				.catch(err =>
				{
					console.error('Grants could not be loaded');
				});
		},

		login ({ state, commit, dispatch }, payload)
		{
			dispatch(
				'request/post',
				payload,
				{ root: true }
			)
				.then(res =>
				{
					const token = res?.token;

					if (!token)
					{
						console.log('did not receive any token from API');
						commit('snackbar/show', {
							msg: 'Falsche E-Mail oder Passwort',
							status: 'error',
						},
							{
								root: true
							});
						return;
					}
					state.auth_token = token;
					localStorage.setItem('auth_token', token);
					
					if (!localStorage.getItem('locale'))
					{console.log('SETTING DEFAULT LOCALE')
						localStorage.setItem('locale', configuration.locale);
					}

					commit('welcomeUser');
					commit('redirectToHomePage');
				})
				.catch(err =>
				{
					console.error('Login error:', err);
					const errorMessage = err.response?.data?.message || 'Falsche E-Mail oder Passwort';

					commit('snackbar/show', {
						msg: errorMessage,
						status: 'error',
					}, { root: true });
				});
		},

		updateOne ({ state, dispatch })
		{
			dispatch(
				'request/put',
				{
					data: {
						email: state.email,
						password: state.password,
					},
					request: {
						path: `users/[id]`,
						options: {
						}
					},
				},
				{
					root: true,
				},
			).then(res =>
			{
				const token = res?.token;

				localStorage.setItem('auth_token', token);

				// dispatch('getMe')
				//	 .then(res => {
				//		 if(!res) return;
				//	 }
				// );
			})
				.catch(err =>
				{
					console.error('User could not be updated');
				});
		},
	},
};
