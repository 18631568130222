import Vue from 'vue';
import Vuex from 'vuex';
import Package from '../../package.json';

Vue.use(Vuex);

import AutoImport from '@/customModules/AutoImport.js';

export default new Vuex.Store({
	modules: AutoImport.getModules(
		require.context('../store/modules', true, /[A-Za-z0-9-_,\s]+\.js$/i),
	),
	state: {
		sidebarOpen: true,
		noNavigation: ['Login', 'Forgot Password'],
		appVersion: Package.version,
	},
	mutations: {
	},
});
