<template lang="pug">
v-container
	div.d-flex.justify-space-between.mb-8()
		Highlight(
			v-for="(highlight, index) of highlights"
			:key="index"
			width="calc(25% - 24px)"
			v-bind="highlight"
		)
	.customCard(
		v-if="Object.keys(selectedEvent).length"
	)
		v-card-title
			h3.myTitle {{ selectedEvent.displayname }}

			v-chip.px-5.ml-4(
				v-if="selectedEvent.active"
				color="success"
			) {{ $t('common.active').toUpperCase() }}
			v-chip.ml-3(
				v-else
				color="error"
			) {{ $t('common.inactive').toUpperCase() }}

			v-spacer

			v-btn(
				v-if="(Array.isArray(formats?.create) && formats.create.length > 0)"
				fab
				small
				depressed
				color="secondary"
				@click="uploadDialog = true"
			).mr-2
				v-icon mdi-upload

			v-btn(
				v-if="Array.isArray(formats?.update) && formats.update.length > 0"
				style="margin-left: auto;"
				fab
				small
				depressed
				color="secondary"
				@click="editDialog = true"
			)
				v-icon mdi-pencil
		v-card-text
			v-row
				v-col
					h3 {{ selectedEvent.description }}
			v-row
				v-col
					div {{ getLabel('supervisor') }}
					div(
						style="font-weight: bold;"
					) {{ selectedEvent.supervisor }}
			v-row
				v-col(
					cols="6"
				)
					div {{ getLabel('start') }}
					div(
						style="font-weight: bold; margin-bottom: 5px;"
					) {{ new Date(selectedEvent.start).toLocaleString($i18n.locale) }}
					div {{ getLabel('end') }}
					div(
						style="font-weight: bold; margin-bottom: 5px;"
					) {{ new Date(selectedEvent.end).toLocaleString($i18n.locale) }}
				v-col(
					cols="6"
				)
					div {{ getLabel('createdAt') }}
					div(
						style="font-weight: bold; margin-bottom: 5px;"
					) {{ new Date(selectedEvent.createdAt).toLocaleString($i18n.locale) }}
					div {{ getLabel('updatedAt') }}
					div(
						style="font-weight: bold; margin-bottom: 5px;"
					) {{ new Date(selectedEvent.updatedAt).toLocaleString($i18n.locale) }}

	ActionLogs(
		:header="$t('objects.event.lastActivities')"
		itemsPerPage="5"
		ref="actionLogs"
	)





	ImportEventForm(
		:show="uploadDialog"
		:header="$t('objects.event.import.title')"
		:subheader="$t('objects.event.import.subtitle')"
		@import-users:success="uploadDialog = false"
		@import-users:fail="uploadDialog = false"
		@import-users:cancel="uploadDialog = false"
	)
	v-dialog(
		v-model="editDialog",
		max-width="700px",
		eager
	)
		v-card
			v-card-title.pb-4
				h2 {{ $t('objects.event.edit.title') }}
				p {{ selectedEvent.displayname }}

			v-card-text.pb-0
				GenericForm(
					ref="form",
					horizontalLayoutGap="24px"
					verticalLayoutGap="0px"
					compensateContainerGapHorizontally
					:format="formats.update",
					:appearances="appearances",
					:initialData="selectedEvent",
					@validation:failure="log('ERROR UPDATE EVENT', $event)"
					@validation:success="submit($event)"
				)

				v-card-actions
					v-spacer

					v-btn.white--text(
						color="cancel"
						@click="editDialog = false; $refs['form'].resetValidation()"
					) {{ $t('common.cancel') }}

					v-btn.white--text(
						color="save"
						@click="$refs['form'].validate()"
					) {{ $t('common.save') }}
</template>

<script>
	import RequestHandler from '@/customModules/libraries/requesthandler.js';

	export default
	{
		name: 'Event',
		components:
		{
			Highlight: require('@/components/Highlight.vue').default,
			ActionLogs: require('@/views/ActionLogs.vue').default,
			GenericForm: require('/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue').default,
			ImportEventForm : require('@/components/ImportEventForm.vue').default,
		},
		created ()
		{
			RequestHandler.requests(this.requests.initialize, this)
			.catch(() => {});
		},
		methods:
		{
			log: console.log,
			submit (event)
			{
				const self = this;
				const data =
				{
					url: 'events/' + this.selectedEvent?._id,
					body: event
				};

				RequestHandler.request(this.requests.update, data)
				.then(function ()
				{
					RequestHandler.requests(self.requests.initialize, self);
					self.$refs.actionLogs.loadActions();
				});
				this.editDialog = false;
			},

			calc_days_to_presentation ()
			{
				const sessions = this.sessions.sort(function(a, b)
				{
					return new Date(a.start) - new Date(b.start);
				});

				if (!sessions.length)
				{
					return 0;
				}
				const difference = new Date(sessions[0].start).getTime() - new Date().getTime();
				const timeOneDay = (1000 * 3600 * 24);

				return Math.round(difference / timeOneDay);
			},

			calc_count_of_speakers ()
			{
				var speakers = [];

				for (const session of this.sessions)
				{
					if (!session.speakers?.length)
					{
						continue;
					}

					for (const speaker of session.speakers)
					{
						const id = (typeof speaker === 'string') ? speaker : speaker._id;

						if (id && !speakers.includes(id))
						{
							speakers.push(id);
						}
					}
				}
				return speakers.length;
			},

			calc_count_of_missing_presentations ()
			{
				var missingFiles = 0;

				for (const session of this.sessions)
				{
					if (!session.files?.length)
					{
						continue;
					}

					for (const file of session.files)
					{
						if (file?.status !== 'uploaded' &&
							file?.status !== 'approved'
						)
						{
							missingFiles++;
						}
					}
				}
				return missingFiles;
			},

			calc_count_of_uploaded_files_last_week ()
			{
				const now = new Date().getTime();
				const uploadsLastWeek = this.fileActions.filter(e =>
				{
					const difference = now - new Date(e.createdAt).getTime();
					const timeOneDay = (1000 * 3600 * 24);

					return difference < (7 * timeOneDay);
				});

				return uploadsLastWeek.length;
			},

			getLabel (name)
			{
				return this.appearances.find(e => e.name === name)?.label;
			},
		},
		computed:
		{
			eventHighlights(){
				const createPath = (_key) => `pages.Event.summarization.${_key}`;

				return [
					{
						id: 'days-to-presentation',
						icon: 'mdi-calendar',
						title: createPath('daysToFirstPresentation'),
						value: '208',
					},
					{
						id: 'count-of-speakers',
						icon: 'mdi-account-multiple',
						title: createPath('amountSpeaker'),
						value: '45',
					},
					{
						id: 'count-of-missing-files',
						icon: 'mdi-microsoft-powerpoint',
						title: createPath('missingPresentations'),
						value: '27',
					},
					{
						id: 'count-of-uploaded-files-last-week',
						icon: 'mdi-upload',
						title: createPath('uploadsLastWeek'),
						value: '2',
					},
				];
			},
			highlights ()
			{
				const self = this;

				return this.eventHighlights.map(e =>
				{
					if (e.id === 'days-to-presentation')
					{
						e.value = self.calc_days_to_presentation().toString();
					}
					if (e.id === 'count-of-speakers')
					{
						e.value = self.calc_count_of_speakers().toString();
					}
					if (e.id === 'count-of-missing-files')
					{
						e.value = self.calc_count_of_missing_presentations().toString();
					}
					if (e.id === 'count-of-uploaded-files-last-week')
					{
						e.value = self.calc_count_of_uploaded_files_last_week().toString();
					}
					return e;
				});
			},
		},
		data: (component) =>
		({
			requests:
			{
				update:
				{
					axios:
					{
						url: 'events',
						method: 'put',
					},
				},
				initialize:
				[
					{
						store: 'selectedEvent',
						axios:
						{
							url: `events/${component.$route.query.eventId}`,
						},
					},
					{
						store: 'sessions',
						axios:
						{
							url: `sessions/?eventId=${component.$route.query.eventId}&limit=1000`,
						},
					},
					{
						store: 'fileActions',
						axios:
						{
							url: `action-logs/?eventId=${component.$route.query.eventId}resource=files&action=upload&limit=1000`,
						},
					},
					{
						store: 'formats.update',
						axios:
						{
							url: 'events/formfields',
							params:
							{
								action: 'update',
							},
						},
					},
				],
			},
			selectedEvent: {},
			sessions: [],
			fileActions: [],
			appearances:
			[
				{
					name: 'title',
					label: component.$t('pages.Event.title'),
				},
				{
					name: 'description',
					label: component.$t('pages.Event.description'),
				},
				{
					name: 'supervisor',
					label: component.$t('pages.Event.supervisor'),
				},
				{
					name: 'start',
					label: component.$t('pages.Event.start'),
				},
				{
					name: 'end',
					label: component.$t('pages.Event.end'),
				},
				{
					name: 'active',
					label: component.$t('pages.Event.active'),
				},
				{
					name: 'createdAt',
					label: component.$t('pages.Event.createdAt'),
				},
				{
					name: 'updatedAt',
					label: component.$t('pages.Event.updatedAt'),
				},
			],
			uploadDialog: false,
			editDialog: false,
			formats: {},
		}),
	}
</script>