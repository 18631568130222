import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/plugins/i18n.js';
import vuetify from '@/plugins/vuetify';

import '@mdi/font/css/materialdesignicons.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

Vue.config.productionTip = false;

new Vue(
	{
		vuetify,
		router,
		store,
		i18n,
		render: h => h(App)
	},
).$mount('#app');