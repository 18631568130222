<template lang="pug">
v-navigation-drawer(
	v-show="$store.state.user?.auth_token && $store.state.user.role !== 'speaker'"
	v-model="$store.state.isSidebar"
	app
	fixed
	clipped
	touchless
	:temporary="$vuetify.breakpoint.mdAndDown"
	:permanent="$vuetify.breakpoint.lgAndUp"
	:width="$vuetify.breakpoint.smAndDown ? '66vw' : 300"
).transparent
	v-list(nav)
		.d-flex
			router-link(to="/", v-show="$vuetify.breakpoint.smAndDown", class="mb-4")
				v-img(
					contain,
					width="140px",
					:src="require('@/assets/logo_colored.png')",
					:title="`Logo ${$store.state.company.firm.long}`",
					:alt="`Logo ${$store.state.company.firm.long}`"
				)

		//- <!-- TODO EDIT PROFILE -->
		.mb-6(
			v-for="(item, i) in navigation"
			:key="i"
			v-show=`
				(!item.requireUrlParameter && !item.excludeUrlParameter) ||
				(item.requireUrlParameter && $route.query[item.requireUrlParameter]) ||
				(item.excludeUrlParameter && !$route.query[item.excludeUrlParameter])
			`
		)
			.navigation-link.d-flex.align-center(:class=`[
				item?.indent ? 'ml-9' : '',
			]`)
				.d-flex.align-center.no-link(@click="navigate(item)")
					v-icon(color="white" :class="$route.path.includes(item?.link) ? 'font-weight-bold tertiary--text' : ''") {{ item.icon }}
					v-subheader.text-h6.white--text(:class="$route.path.includes(item?.link) ? 'font-weight-bold tertiary--text' : ''") {{ $tc(item.label, item.indexInI18n) }}
				v-divider.mx-auto
	template(v-slot:append)
		span.white--text(
			style="padding: 5px 15px; font-size: x-small;"
		) Version {{ $store.state.appVersion }}, powered by G+B Interactive<sup>&#174;</sup>
</template>

<script>
import VueRouterHelper from '/node_modules/@gb-net/vue-router-utility/src/vue_router_helper.js';

export default
{
	name: 'Navigation',
	computed:
	{
		navigation ()
		{
			const menu =
			[
				{
					label: "Portal.settings.overview",
					icon: "mdi-home",
					link: "/home",
					excludeUrlParameter: "eventId",
					serverRequiredGrants: "rooms",
				},
				{
					label: "Project.User.userAccounts",
					indexInI18n: 2,
					icon: "mdi-account-supervisor",
					link: "/userAccounts",
					excludeUrlParameter: "eventId",
					serverRequiredGrants: "users",
					hide: () => (this.$store.state.user?._events ?? []).length > 0
				},
				/* {
					label: "Portal.settings.title",
					indexInI18n: 2,
					icon: "mdi-cogs",
					link: "/settings",
					excludeUrlParameter: "eventId",
					serverRequiredGrants: "users", // TODO what enter here?
				}, */

				{
					label: "objects.event.all",
					icon: "mdi-chevron-left",
					background: "grey",
					link: "/",
					requireUrlParameter: "eventId",
					clearUrlParameter: true,
					serverRequiredGrants: "sessions", // TODO
				},
				{
					label: "objects.event.title",
					indexInI18n: 1,
					icon: "mdi-home",
					link: "/event",
					requireUrlParameter: "eventId",
					serverRequiredGrants: "sessions",
				},
				{
					label: "Project.Sidebar.participant",
					icon: "mdi-account-supervisor",
					link: "/participants",
					requireUrlParameter: "eventId",
					serverRequiredGrants: "users",
					indent: true,
				},
				{
					label: "Project.Sidebar.sessions",
					indexInI18n: 2,
					icon: "mdi-format-list-bulleted",
					link: "/sessions",
					requireUrlParameter: "eventId",
					serverRequiredGrants: "sessions",
					indent: true,
				},
				{
					label: "objects.room.title",
					indexInI18n: 2,
					icon: "mdi-view-dashboard",
					link: "/rooms",
					requireUrlParameter: "eventId",
					serverRequiredGrants: "rooms",
					indent: true,
				},
				/* {
					label: "Portal.settings.title",
					indexInI18n: 2,
					icon: "mdi-cogs",
					link: "/eventsettings",
					requireUrlParameter: "eventId",
					serverRequiredGrants: "users",
					indent: true,
				}, */
			];
			
			return menu.filter((item) =>
			{
				if (
					this.$store.state.user?.role === "speaker" &&
					item.serverRequiredGrants === "users"
				)
				{
					return false;
				}
				
				if (item?.hide?.())
				{
					return false;
				}
				const userCanAny = this.$store.getters["authorization/userCan"](
					"read",
					item.serverRequiredGrants,
					"any"
				);
				const userCanOwn = this.$store.getters["authorization/userCan"](
					"read",
					item.serverRequiredGrants,
					"own"
				);
				const isOwnEvent = this.$store.state.user?._events?.includes(this.$route.query?.eventId);

				return userCanAny || (userCanOwn && isOwnEvent);
			});
		},
	},
	methods:
	{
		navigate (item)
		{
			if (!item.requireUrlParameter || item.clearUrlParameter)
			{
				return VueRouterHelper.navigate(item.link);
			}
			
			if (VueRouterHelper.routesEqual(
				this.$router.resolve(item.link + '?' + Utility.object.toUrlParameterString(this.$route.query)).resolved,
				this.$router.currentRoute
			))
			{
				return this.$router.go(0);
			};

			return this.$router.push({ path: item.link, query: { eventId: this.$route.query?.eventId } });
		}
	}
}
</script>

<style lang="sass">
.navigation-link
	cursor: pointer
	user-select: none
</style>