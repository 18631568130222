<template lang="pug">
v-container
	div.customCard.mb-6
		h1.myTitle.mb-6 {{ $t('pages.EventSettings.title') }}
		GenericForm(
			ref="form-settings"
			horizontalLayoutGap="24px"
			verticalLayoutGap="0px"
			compensateContainerGapHorizontally
			:format="formats?.settings?.update ?? []"
			:appearances="appearances.settings"
			:initialData="settings"
			@validation:success="request(requests.update.settings, {body: $event}).then(() => request(requests.get.settings).then((response) => {settings = response.data}))"
		)
		.d-flex
			v-spacer
			v-btn.white--text(
				color="save"
				@click="$refs['form-settings'].validate()"
			) {{ $t('common.save') }}
	ObjectManagementList(
		:items="templates"
		:formats="formats.templates"
		:appearances="appearances.templates"
		:dataTableOptions.sync="dataTableOptions"
		:loading.sync="loading"
		:showSearch="false"
		:showAdd="false"
		:showDelete="false"
		:textUpdate="{ header: $t('objects.mailing.template.edit') }"
		:actionColumnLabel="$t('common.actions')"
		@update-validation:success="request(requests.update.templates, {url: requests.update.templates.axios.url + '/' + $event.templateId, body: $event}).then(() => request(requests.get.templates).then((response) => {templates = response.data;log(response.data)}))"
	)
		template(#header)
			h1.myTitle.mb-4 {{ $t('pages.EventSettings.templateTitle') }}
</template>

<script>
import GenericForm from '/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue';
import RequestHandler from '@/customModules/libraries/requesthandler.js';

export default
{
	name: 'Admin',
	components:
	{
		GenericForm,
		ObjectManagementList: require('@/components/ObjectManagementList.vue').default
	},
	created ()
	{
		RequestHandler.requests(this.requests.initialize, this).then(
			() =>
			{
				this.formats.settings.update.map(
					(field) =>
					{
						if (field.type === 'boolean')
						{
							field.type = 'bool';
						}
						
						return field;
					}
				);
				
				this.formats.templates.update.map(
					(field) =>
					{
						if (field.type === 'boolean')
						{
							field.type = 'bool';
						}
						
						if (field.type === 'number')
						{
							field.type = 'int';
						}
						
						return field;
					}
				);
			}
		);
	},
	methods:
	{
		log: console.log,
		
		request: RequestHandler.request
	},
	computed:
	{
		requests ()
		{
			return {
				update:
				{
					settings:
					{
						axios:
						{
							url: this.urlBase,
							method: 'put'
						}
					},
					templates:
					{
						axios:
						{
							url: this.urlBase + '/templates',
							method: 'put'
						}
					}
				},
				get:
				{
					settings:
					{
						store: 'settings',
						axios:
						{
							url: this.urlBase
						}
					},
					templates:
					{
						store: 'templates',
						axios:
						{
							url: this.urlBase + '/templates'
						}
					},
				},
				initialize:
				[
					{
						store: 'formats.settings.update',
						axios:
						{
							url: this.urlBase + '/formfields',
							params:
							{
								action: 'update'
							}
						}
					},
					{
						store: 'settings',
						axios:
						{
							url: this.urlBase
						}
					},
					{
						store: 'formats.templates.update',
						axios:
						{
							url: this.urlBase + '/templates/formfields',
							params:
							{
								action: 'update'
							}
						}
					},
					{
						store: 'templates',
						axios:
						{
							url: this.urlBase + '/templates'
						}
					},
				]
			}
		}
	},
	data: (component) =>
	({
		urlBase: 'mailing/' + component.$route.query.eventId + '/settings',
		settings: null,
		templates: [],
		templateActive: {},
		formats:
		{
			settings: {update: []},
			templates: {update: []}
		},
		appearances:
		{
			settings:
			[
				{
					name: 'dispatch_url',
					label: component.$t('objects.mailing.settings.dispatchUrl')
				},
				{
					name: 'active',
					label: component.$t('common.active'),
					view: 'checkbox'
				},
			],
			templates:
			[
				{
					name: 'dispatch_project',
					label: component.$t('objects.mailing.templates.dispatchProject')
				},
				{
					name: 'templateId',
					label: component.$t('objects.mailing.templates.templateId')
				},
				{
					name: 'active',
					label: component.$t('common.active'),
				}
			]
		},
		loading: false,
		dataTableOptions: {},
	})
}
</script>