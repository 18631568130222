<template lang="pug">
router-link(to="/")
	v-img(
		contain,
		width="140px",
		:src="logo",
		:title="`Logo ${$store.state.company.firm.long}`",
		:alt="`Logo ${$store.state.company.firm.long}`"
	)
</template>

<script>


export default
{
	name: 'Logo',
	props:
	[
		'logo'
	],
}
</script>
