<template lang="pug">
v-dialog(
	v-model="show",
	width="calc(400px + 40vw)",
	max-width="700px",
	persistent
)
	v-card
		.py-3.px-4
			h2 {{ header }}
			p {{ subheader }}

			p(
				style="font-size: small;"
			) {{ $t('pages.Participants.import.tableFormat') }} 
			v-simple-table(
				dense
			)
				template
					thead
						tr
							th(
								class="text-left"
								style="font-size: x-small;"
								v-for="item in examples"
							  	:key="item.key"
							) {{ item.key }}{{ item.required ? '*' : '' }}
					tbody
						tr
							th(
								class="text-left"
								style="font-size: x-small; font-weight: normal;"
								v-for="item in examples"
							  	:key="item.key"
							) {{ item.value }}

			p(
				class="pa-4"
				style="font-size: small; color: #666;"
			) {{ $t('pages.Participants.import.validRoleValues') }} 
				span(
					style="font-weight: bold;"
				) {{ examples.find(e => e.key === 'role').valid }}

			GenericForm(
				ref="importForm",
				:format="uploadFormat",
				:appearances="appearances",
				:initialData="initialImportData",
				@validation:success="importUsers($event)"
			)

			template
				v-card-actions
					v-spacer
					v-btn.grey.white--text(
						@click="$emit('import-users:cancel')"
					) {{ $t('common.cancel') }}
					v-btn.green.white--text(
						@click="$refs.importForm.validate()"
					) {{ $t('common.import') }}
</template>

<script>
export default {
	name: 'ImportUsersForm',

	props:
	[
		'header',
		'subheader',
		'show'
	],

	components:
	{
		GenericForm: require('/node_modules/@gb-net/vue-generic-form/src/components/GenericForm.vue').default,
	},

	data: (component) => ({
		urlBase: 'users',

		uploadFormat: [{
			name: 'file',
			required: false,
			type: 'file',
			extensions: ['xlsx']
		}],

		appearances: [
			{
				name: 'file',
				label: component.$t('pages.Participants.import.label')
			}
		],

		initialImportData: {},

		examples:
		[
			{
				key: 'ID',
				value: '1',
				required: true
			},
			{
				key: 'lastname',
				value: 'Mustermann',
				required: true
			},
			{
				key: 'firstname',
				value: 'Max',
				required: true
			},
			{
				key: 'email',
				value: 'mm@company.org',
				required: true
			},
			{
				key: 'password',
				value: '1234',
				required: true
			},
			{
				key: 'company',
				value: 'Company Inc.',
				required: false
			},
			{
				key: 'phone',
				value: '0123/4567-8',
				required: false
			},
			{
				key: 'role',
				value: 'speaker',
				valid: 'admin, operator, speaker',
				required: true
			},
		]
	}),

	methods:
	{
		log: console.log,

		async importUsers (data)
		{
			const headers = {};
			const eventIdQuery = this.$route.query.eventId ? `?event=${this.$route.query.eventId}` : '';
			const url = `${this.urlBase}/bulk/${eventIdQuery}`;

			headers['content-type'] = 'application/octet-stream';
			headers['stream-origin-file-name'] = encodeURIComponent(`${data?.file?.name}`);
			data = data.file;

			await this.$store.dispatch('request/post', {
				data,
				request: {
					path: url,
					options: {
						headers
					},
				},
			})
			.then((res) =>
			{
				if (!res) throw new Error();

				this.$emit('import-users:success');

				this.$store.commit('snackbar/show', {
					msg: ('Import erfolgreich ausgeführt.'),
					status: 'success',
				});

			})
			.catch(err =>
			{
				const response = err?.response?.data?.message || err?.response?.statusText || err?.message;
				const template = typeof response === 'string' ? 2 : 0;

				this.$store.commit('snackbar/show', {
					msg: this.$tc('common.errorOccured', template, { errorMsg: response}),
					status: 'error',
				});

				this.$emit('import-users:fail');
				this.log('IMPORT ERROR', err);
			});;

			this.initialImportData = {};
		},
	}
}
</script>
