<template lang="pug">
v-snackbar.mySnackbar(
	v-model="$store.state.snackbar.status",
	light
	bottom
	:timeout="timeout"
)
	span(
		:class="`${$store.state.snackbar.status}--text`"
		v-html="$store.state.snackbar.msg"
	)
	template(v-slot:action="{ attrs }")
		v-btn.ml-5.my-5(
			color="secondary",
			text,
			icon,
			v-bind="attrs",
			@click="$store.state.snackbar.status = false"
		)
			v-icon mdi-close
</template>

<script>
export default
{
	name: 'Snackbar',
	computed:
	{
		timeout ()
		{
			return this.$store.state.snackbar.timeout;
		},
	},
}
</script>