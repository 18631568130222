export default {
	namespaced: true,
	state: {
		path: 'formfields',
	},
	getters: {
	},
	mutations: {
		show(state, payload){
			Object.assign(state, payload);
		}
	},
	actions: {
		get({ state, dispatch }, payload){
			const { params, path } = payload;

			return dispatch(
				'request/get',
				{
					request: {
						path: `${path}/formfields`,
						params,
					},
				},
				{
					root: true,
				},
			).then(res => res);
		},
	},
};
