export default
{
	namespaced: true,
	state:
	{
		access: null,
	},
	getters:
	{
		userCan: (state) => (action, object, scope = 'any') =>
		{
			const access = state?.access;

			if (!access) return false;

			return state.access?.filter((serverPermission) =>
			{
				return serverPermission.action === action &&
					(serverPermission.resource === object || serverPermission.resource === `event-${object}`) &&
					(serverPermission.possession === scope || serverPermission.possession === 'any');
			}).length > 0;
		}
	},
};
